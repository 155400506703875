/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "DroidKufi-Regular";
  src: url("./assets/fonts/DroidKufi-Regular.ttf") format("truetype");
}

$hint-color: #8989a2;
// .alert-button-group .alert-button-group-vertical {
//   flex-direction: row !important;
// }
// .alert-head.sc-ion-alert-md+.alert-message.sc-ion-alert-md {
//   padding-top: 0;
// }
// .alert-head.sc-ion-alert-ios+.alert-message.sc-ion-alert-ios {
//   padding-top: 0;
// }
// .alert-button-group.sc-ion-alert-md
// {
//   display: -webkit-box;
//   margin-inline: auto;

// }
// .alert-button-group.sc-ion-alert-ios
// {
//   display: -webkit-box;
// }
iframe {
  width: 100%;
}

.select-icon {
  width: 25px;
  height: 25px;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  opacity: unset !important;

  margin-inline: 35px;
}

.action-sheet-button.profile {
  font-weight: bold;
  margin-inline-end: 9px;
}

.action-sheet-icon.sc-ion-action-sheet-md {
  margin-inline-end: 10px;
}

.action-sheet-cancel {
  font-size: 20px;
}

// @import "./assets/css/css/app.min.css";
// @import "./assets/css/css/fa.min.css";
// @import "./assets/css/css/toastr.min.css";
// @import "./assets/css/css/style.css";
// @import "./assets/css/css/meeting.css";

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
span,
a,
label {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

html,
td,
th,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
span,
button,
input,
a,
ion-toast,
label,
ion-segment-button,
ion-item,
ion-input,
ion-label,
ion-title,
ion-select,
ion-text,
ion-fab-button,
ion-badge,
ion-button {
  font-family: "DroidKufi-Regular", sans-serif !important;
  // letter-spacing: -0.1px;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

a {
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100% !important;
}

ion-content {
  --padding-bottom: 15px;
  --padding-top: 15px;
  --padding-end: 15px;
  --padding-start: 15px;
}

ion-title {
  font-size: 15px;
  text-align: center;
  // color: white;
}

app-join-meet {
  ion-content {
    --padding-bottom: 0px;
    --padding-top: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
  }
}

textarea,
input {
  caret-color: var(--ion-color-primary);
}

//centered ion title

.block_title {
  text-align: start;

  h2 {
    color: var(--ion-color-primary);
    font-size: 16px;
    border-inline-start: 4px solid var(--ion-color-secondary);
    padding-inline-start: 5px;
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  p {
    font-size: 15px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  span {
    color: var(--ion-color-primary);
  }
}

ion-toolbar {
  --background: linear-gradient(96deg,
      rgba(52, 17, 69, 1) 0%,
      rgba(103, 34, 137, 1) 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  --border-width: 0px;
  --min-height: 50px;
}

.header-md::after {
  display: none;
}

.md ion-title {
  font-size: 16px;
  text-align: center;
  width: 60%;
  margin: auto;
  position: absolute;
  left: 20%;
  top: 0px;
  height: 100%;
}

ion-button {
  --border-radius: 5px;
  border-radius: 5px;
  overflow: hidden !important;
  --padding-bottom: 10px;
  --padding-top: 10px;
  min-height: 48px;
  letter-spacing: 0;
  font-size: 15px;
  --padding-bottom: 0;
  --padding-top: 0;
  // --padding-end:0px;
  // --padding-start:0px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

ion-searchbar {
  font-size: 14px;
  --icon-color: var(--ion-color-primary);
  padding-bottom: 10px !important;

  .searchbar-input-container {
    height: 40px !important;
    font-size: 14px;

    input {
      font-size: 14px !important;
      border: 1px solid rgba(0, 0, 0, 0.15) !important;
    }
  }
}

.cat_sec {
  border-radius: 10px;
  padding: 15px 15px;
  position: relative;
  transition: all ease-in-out 300ms;
  height: 100%;
  overflow: hidden;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    // background: var(--ion-color-white);
    // background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
    background-image: url("../src/assets/wave-none.svg");
    opacity: 0.5;
    right: 0px;
    bottom: 0px;
    // border-top-left-radius: 100%;
    transition: all ease-in-out 300ms;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }

  .inner_div {
    position: relative;
    z-index: 1;

    &:hover {
      text-decoration: none;
    }

    img {
      height: 35px;
      width: 35px;
      object-fit: cover;
      // margin-bottom: 15px;
    }

    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
      color: var(--ion-color-white);
      font-size: 16px;
      text-align: start;
      font-size: 15px;
      line-height: 2;

      b {
        color: var(--ion-color-white);
      }
    }
  }
}

.content_section .item_1 .cat_sec {
  background: #cd2b36;
}

.content_section .item_2 .cat_sec {
  background: #019064;
}

.content_section .item_4 .cat_sec {
  background-color: #7605ae;
}

.content_section .item_3 .cat_sec {
  background-color: #ff6600;
}

.content_section .item_5 .cat_sec {
  background-color: #7279b1;
}

.content_section .item_6 .cat_sec {
  background-color: #bd0875;
}

.content_section .item_7 .cat_sec {
  background-color: #2c3ec9;
}

.content_section .item_8 .cat_sec {
  background-color: #1e9bb1;
}

.content_section .item_9 .cat_sec {
  background-color: #ca2fdf;
}

.content_section .item_10 .cat_sec {
  background-color: #27922d;
}

.content_section .item_11 .cat_sec {
  background-color: #b18a0a;
}

.content_section .item_12 .cat_sec {
  background-color: #e94e4e;
}

ion-buttons {
  ion-back-button {
    font-size: 14px;
    --icon-padding-end: 10px;
    --icon-padding-start: 10px;
  }

  ion-button {
    height: auto;
    margin: auto;
    min-height: 32px;

    ion-icon {
      font-size: 24px;
    }
  }
}

ion-toggle {
  height: 24px;
  width: 40px;
  --handle-height: 14px;
  --handle-spacing: 5px;
  --handle-width: 14px;
}

.default-btn {
  --ion-color-base: transparent !important;
  --background: transparent;
  background: linear-gradient(-45deg,
      #fa3838,
      var(--ion-color-secondary),
      #fa3838,
      var(--ion-color-secondary));
  --background-activated: linear-gradient(-45deg,
      #fa3838,
      var(--ion-color-secondary),
      #fa3838,
      var(--ion-color-secondary));
  --background-focused: linear-gradient(-45deg,
      #fa3838,
      var(--ion-color-secondary),
      #fa3838,
      var(--ion-color-secondary));
  --background-hover: linear-gradient(-45deg,
      #fa3838,
      var(--ion-color-secondary),
      #fa3838,
      var(--ion-color-secondary));
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #fff;
  color: #fff;

  button {
    background: transparent !important;
  }
}

.green-btn {
  --ion-color-base: transparent !important;
  --background: transparent;
  background: linear-gradient(-45deg,
      #0AAF63,
      var(--ion-color-secondary),
      #0AAF63,
      var(--ion-color-secondary));
  --background-activated: linear-gradient(-45deg,
      #0AAF63,
      var(--ion-color-secondary),
      #0AAF63,
      var(--ion-color-secondary));
  --background-focused: linear-gradient(-45deg,
      #0AAF63,
      var(--ion-color-secondary),
      #0AAF63,
      var(--ion-color-secondary));
  --background-hover: linear-gradient(-45deg,
      #0AAF63,
      var(--ion-color-secondary),
      #0AAF63,
      var(--ion-color-secondary));
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #fff;
  color: #fff;

  button {
    background: transparent !important;
  }
}

.second-btn {
  --ion-color-base: transparent !important;
  --background: transparent;
  // background: linear-gradient(-45deg, #672289, var(--ion-color-primary), #672289, var(--ion-color-primary));
  // --background-activated: linear-gradient(-45deg, #672289, var(--ion-color-primary), #672289, var(--ion-color-primary));
  // --background-focused: linear-gradient(-45deg, #672289, var(--ion-color-primary), #672289, var(--ion-color-primary));
  // --background-hover: linear-gradient(-45deg, #672289, var(--ion-color-primary), #672289, var(--ion-color-primary));
  background: var(--ion-color-primary);
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #fff;
  color: #fff;

  button {
    background: transparent !important;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.btn_small {
  height: 40px;
  min-height: 40px;
  --padding-start: 10px;
  --padding-end: 10px;
  font-size: 12px;
}

.btn_round {
  border-radius: 35px;
}

ion-tab-button {
  ion-icon {
    font-size: 18px;
  }

  ion-label {
    font-size: 13px;
    // font-size: 12px;
  }
}

ion-content {
  --background: transparent;
  background: #fff;
  background-image: url("assets/background.svg");
  background-repeat: no-repeat;
  background-size: 350px 100%;

  // &::before{
  //     content: "";
  //     display: block;
  //     height: 100%;
  //     width: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     background-repeat: no-repeat;
  //     opacity: 0.1;
  //     background-size: 100% 100%;
  // }
  .content_section {
    z-index: 2;
  }

  ion-list {
    --background: transparent;
    background: transparent !important;
  }
}

app-login {
  background-image: url("assets/background.svg");
  background-repeat: no-repeat;
  background-size: 350px 100%;

  ion-header {
    ion-buttons {
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
  }

  ion-content {
    background: transparent;
    background-image: none;
  }
}

ion-item {
  background: transparent !important;
  --background: transparent !important;
  --background-hover: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding-left: 0px;
  padding-right: 0px;

  ion-icon {
    margin-inline-end: 15px;
    font-size: 18px;
  }

  ion-label {
    font-size: 14px !important;
    font-weight: 500;
    margin: 0;
  }
}

ion-list-header {
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  min-height: auto;

  ion-label {
    margin: 0;
  }
}

.input_item {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px !important;
  background: #fff !important;
  --background: #fff !important;
  border: 1px inset rgba($color: #000000, $alpha: 0.15) !important;
  margin-top: 15px;
  height: 48px;
  --min-height: 48px;

  ion-input {
    --background: transparent !important;
    background: none !important;
    --color: var(--ion-color-dark);
    --padding-bottom: 5px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
    --padding-top: 5px !important;
    --placeholder-color: var(--ion-color-dark);
    --placeholder-opacity: 0.35 !important;
    font-size: 14px !important;
    overflow: hidden !important;
    height: 100%;
  }

  input {
    height: 100%;
    background: transparent !important;
    background-color: transparent !important;
  }

  ion-icon {
    color: #341145;
    font-size: 20px;
    margin: 0px;
    padding-inline-end: 14px;
    padding-inline-start: 14px;
  }

  ion-label {
    margin: 0px;
    padding-inline-end: 15px;
    padding-inline-start: 15px;
  }

  .palceholder {
    opacity: 0.5;
  }
}

.email_input {
  direction: ltr;
}

.phone_input {
  direction: ltr;
  padding-right: 14px;

  ion-select {
    min-width: 70px;
    width: fit-content;
    padding-inline-end: 10px;
    padding-inline-start: 10px;
    font-size: 14px;
  }

  ion-input {
    padding-inline-start: 0px;
    --padding-start: 0px;
    direction: rtl;

    input {
      padding-inline-start: 0px !important;
    }
  }
}

.searchbar_input {
  margin-bottom: 15px;
  --min-height: 40px;
  height: 40px;
  border: 0px !important;

  ion-icon {
    padding-inline-end: 5px;
    padding-inline-start: 10px;
  }

  ion-button {
    margin: 0px;
    --border-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    background: transparent !important;
    --background: var(--ion-color-secondary);
    height: 40px;
    min-height: 40px;
    --padding-start: 8px;
    --padding-end: 8px;
    width: 50px;

    .button-native {
      background: transparent !important;

    }
  }

  ion-input {
    padding-inline-start: 5px;
    --padding-start: 5px !important;
  }
}

[dir="ltr"] .searchbar_input {
  ion-button {
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}

ion-toolbar {
  .searchbar_input {
    margin: 0px 10px 10px;
  }
}

::-webkit-input-placeholder {
  font-size: 14px !important;
}

::-moz-placeholder {
  font-size: 14px !important;
}

:-ms-input-placeholder {
  font-size: 14px !important;
}

:-moz-placeholder {
  font-size: 14px !important;
}

.textarea_item {
  margin-top: 15px;
  border: 1px solid rgba($color: #000000, $alpha: 0.15) !important;
  border-radius: 5px !important;
  background: #fff !important;
  --background: #fff !important;

  ion-label {
    color: #fff !important;
    margin: 0px;
    font-size: 14px !important;
    margin-bottom: 5px;
  }

  ion-textarea {
    border-radius: 5px !important;
    --border-radius: 5px !important;
    --background: #fff !important;
    --color: var(--ion-color-dark) !important;
    --padding-bottom: 10px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
    --padding-top: 10px !important;
    --placeholder-color: var(--ion-color-dark) !important;
    --placeholder-opacity: 0.35 !important;
    min-height: 100px !important;
    font-size: 14px !important;
    overflow: hidden !important;
    margin-top: 0;

    textarea {
      min-height: 100px !important;
    }
  }
}

.select_item {
  // background: transparent;
  // --background: transparent;
  background: #fff !important;
  --background: #fff !important;
  margin-top: 15px;
  border: 1px solid rgba($color: #000000, $alpha: 0.15) !important;
  border-radius: 5px !important;
  height: 48px;

  ion-label {
    color: #fff !important;
    margin: 0px;
    font-size: 14px !important;
    margin-bottom: 5px;
  }

  ion-select {
    border-radius: 5px !important;
    width: 100%;
    font-size: 14px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
  }
}

.input_upload {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px !important;
  background: #fff !important;
  --background: #fff !important;
  border: 1px solid rgba($color: #000000, $alpha: 0.15) !important;
  // border: 1px solid rgba($color: #341145, $alpha: 0.15) !important;
  margin-top: 15px;

  ion-input {
    --background: transparent !important;
    background: none !important;
    --color: var(--ion-color-dark);
    --padding-bottom: 5px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
    --padding-top: 5px !important;
    --placeholder-color: var(--ion-color-dark);
    --placeholder-opacity: 0.35 !important;
    font-size: 14px !important;
    overflow: hidden !important;
    opacity: 0;

    width: 50%;
    height: 40px;
    position: absolute;
    top: 0px;
    // left: 0px;
  }

  ion-label {
    display: flex !important;
    align-items: center !important;
    max-width: 100% !important;

    ion-icon {
      margin-inline-end: 5px;
      color: #252525;
      opacity: 0.5;
      padding: 0;
      min-width: 20px;
    }

    span {
      font-weight: normal;
      font-size: 14px;
      color: #252525;
      opacity: 0.5;
      white-space: normal;
      line-height: 1.5em;
      padding: 5px;
    }
  }

  input {
    background: transparent !important;
    background-color: transparent !important;
    font-size: 14px !important;
  }

  ion-icon {
    color: #341145;
    font-size: 20px;
    margin: 0px;
    padding-inline-end: 14px;
    padding-inline-start: 14px;
  }

  ion-label {
    margin: 0px;
    padding-inline-end: 15px;
    padding-inline-start: 15px;
  }

  .palceholder {
    opacity: 0.5;
  }
}

.countdown_section {
  span {
    line-height: 1;

    .item {
      width: 50px !important;
      min-width: 50px !important;
      text-align: center !important;
      font-weight: 700 !important;
      font-size: 16px !important;
      color: #fff !important;
      margin: 0;
      padding: 2px;
      display: inline-flex;
      justify-content: center;
      line-height: 1;
    }
  }
}

::-webkit-input-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
  font-family: "DroidKufi-Regular" !important;
}

::-moz-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
  font-family: "DroidKufi-Regular" !important;
}

:-ms-input-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
}

:-moz-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
  font-family: "DroidKufi-Regular" !important;
}

ion-grid {
  padding: 0;
}

.custom_actionSheet {
  --background: #ffffff;

  .action-sheet-title {
    font-size: 14px;
    color: var(--ion-color-dark);
    font-weight: bold;
  }

  .action-sheet-group {
    border-radius: 10px;

    .action-sheet-button {
      font-size: 14px;
      color: var(--ion-color-dark);
      font-weight: normal;
      padding: 0px;

      .action-sheet-button-inner {
        justify-content: start !important;
        pointer-events: auto !important;
        width: 100%;
        height: 100%;
        padding: 0px 15px;
      }

      ion-icon {
        margin: 0px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px !important;
        color: #fff;
        border-radius: 30px;
        margin-inline-end: 10px !important;
      }
    }

    .action-sheet-button:nth-child(1) {
      ion-icon {
        background-color: var(--ion-color-primary);
      }
    }

    .action-sheet-button:nth-child(3) {
      ion-icon {
        background-color: var(--ion-color-success);
      }
    }

    .action-sheet-button:nth-child(2) {
      ion-icon {
        background-color: var(--ion-color-secondary);
      }
    }

    .action-sheet-button:nth-child(4) {
      ion-icon {
        background-color: var(--ion-color-danger);
      }
    }
  }

  .action-sheet-group-cancel {
    border-radius: 10px;
    background-color: var(--ion-color-primary);
    border-radius: 10px;

    .action-sheet-cancel {
      height: 44px;
      padding: 10px 20px;
      background: linear-gradient(-45deg,
          #fa3838,
          var(--ion-color-secondary),
          #fa3838,
          var(--ion-color-secondary));
      background-size: 800% 400%;
      transition: all 0.5s ease-in-out;
      animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
      // color       : #ffffff;
      // background  : var(--ion-color-primary);
      // background  : linear-gradient(90deg, rgba(121, 68, 160, 1) 0%, rgba(214, 82, 212, 1) 100%) !important;
      // --background: linear-gradient(90deg, rgba(121, 68, 160, 1) 0%, rgba(214, 82, 212, 1) 100%) !important;

      .action-sheet-button-inner {
        justify-content: center !important;
        color: #ffffff;
      }

      &::after {
        display: none;
      }
    }
  }
}

ion-popover {
  --max-height: 90vh !important;
  --min-width: 270px !important;
  --backdrop-opacity: 0.5 !important;
  //   padding: 15px!important;

  .pop_item {
    --padding-start: 12px;
    --padding-end: 12px;
    color: var(--ion-color-primary);

    ion-icon {
      color: var(--ion-color-secondary);
      margin-inline-end: 8px;
    }

    ion-img {
      width: 20px;
      margin-inline-end: 8px;
    }
  }
}

.auto-width-popover {
  --min-width: unset !important;

  ion-item {
    --padding-start: 10px;
  }
}

.home-popover {
  --min-width: 270px !important;

  ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
  }

  ion-item {
    border: 1px solid #ddd;
    display: inline-table;
  }

  ion-item:last-child {
    border: unset;
  }
}

.audio-speed-popover {
  --min-width: unset !important;
  --max-width: 100px !important;
  --max-height: 200px !important;

  p {
    padding: 7px;
    margin: 0px;
  }
}

ion-select-popover {
  ion-item {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }
}

// .alert-wrapper {

//   .alert-button-group {
//     .alert-button {
//       font-size: 14px;
//     }
//   }
// }

// .custom_alert {
//   --width: calc(100% - 40px);
//   --max-width: 400px;
//   --background: #ffffff;

//   .alert-wrapper {
//     padding: 15px;
//     background: #ffffff;

//     .alert-head {
//       padding: 0px;
//       padding-bottom: 10px;
//       border-bottom: 1px solid #ddd !important;

//       .alert-title {
//         margin: 0px;
//         margin-top: 0px;
//         // font-weight: normal;
//         font-size: 16px;
//       }
//     }

//     .alert-message {
//       display: none;
//       padding-top: 16px;
//       font-size: 14px;
//     }

//     .alert-radio-group {
//       border-top: 0px !important;
//       margin-bottom: 10px;
//       border-bottom: 1px solid #ddd !important;

//       .alert-radio-button {
//         height: 44px;
//       }

//       .alert-radio-label {
//         padding-top: 5px !important;
//         padding-bottom: 5px !important;
//         padding-inline-start: 0px !important;
//         padding-inline-end: 0px !important;
//         font-size: 14px;
//       }
//     }

//     .alert-input-group {
//       padding: 0px;
//       padding-bottom: 20px;

//       input {
//         height: 40px;
//         border-radius: 10px;
//         padding: 6px 20px;
//         background: #ffffff;
//         border: 1px solid #ddd !important;
//       }
//     }

//     .alert-button-group {
//       .alert-button {
//         border: 0px !important;
//         font-size: 14px;
//       }

//       .cancel_btn {
//         color: var(--ion-color-primary) !important;
//         border-radius: 10px;
//         margin-inline-end: 5px;
//         min-width: calc(50% - 5px);

//         .alert-button-inner {
//           color: var(--ion-color-dark);
//           pointer-events: auto !important;
//           width: 100%;
//           height: 100%;
//           padding: 0px 15px;
//         }
//       }

//       .confirm_btn {
//         border-radius: 10px;
//         margin-inline-start: 5px;
//         min-width: calc(50% - 5px);
//         color: var(--ion-color-white) !important;

//         .alert-button-inner {
//           pointer-events: auto !important;
//           width: 100%;
//           height: 100%;
//           padding: 0px 15px;
//         }
//       }
//     }
//   }
// }

ion-select-popover {
  ion-list {
    ion-item {
      --border-width: 0px !important;
      --border-color: #ffffff;
      border-bottom: 1px solid #ddd !important;
    }

    ion-item:last-child {
      border-bottom: 0px !important;
    }
  }
}

.swiper-wrapper {
  margin-bottom: 20px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
  margin-bottom: 0px !important;
  bottom: 0px;
  // display: none;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 20px;
  height: 4px;
  border-radius: 0px;
  background: #fa3838;
}

ion-row {
  margin-inline-start: -5px;
  margin-inline-end: -5px;
}

// ion-col[size='6']:nth-child(even) {
//   padding-inline-end: 0px !important;
// }

// ion-col[size='6']:nth-child(odd) {
//   padding-inline-start: 0px !important;
// }

// ion-col[size='6']:nth-child(odd)+ion-col {
//   padding-inline-end: 0px !important;
// }

// ion-col[size='12'] {
//   padding-inline-start: 0px !important;
//   padding-inline-end: 0px !important;
// }

ion-footer {
  ion-col {
    padding: 0px 8px;
  }

  ion-col[size="6"]:nth-child(odd) {
    padding-inline-end: 2px;
  }

  ion-col[size="6"]:nth-child(odd)+ion-col {
    padding-inline-start: 2px;
  }
}

.brife {
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    display: block;
    text-align: center;

    ion-icon {
      font-size: 70px;
      color: var(--ion-color-primary);
      margin-bottom: 20px;
    }

    .notsolved_img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      margin-top: 20px;

      img {
        min-width: 150px;
        max-width: 150px;
        margin-bottom: 0px;
      }

      span {
        font-size: 65px;
        color: var(--ion-color-white);
        font-weight: 700;
        margin-right: 20px;
        transform: rotate(-5deg);
        font-family: revert !important;
        position: absolute;
      }
    }

    h4 {
      margin-top: 0px;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: bold;
      color: var(--ion-color-primary);
    }
  }
}

.loading_spin,
.no_network,
.notification_msg,
.no_content,
.error_msg,
.message_content,
.success_msg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;

  .inner {
    display: block;
    text-align: center;

    ion-icon {
      font-size: 70px;
      color: var(--ion-color-primary);
      margin-bottom: 10px;
    }

    img {
      min-width: 200px;
      max-width: 200px;
      margin-bottom: 0px;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: bold;
      color: var(--ion-color-primary);
    }
  }
}

.still_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;

  .inner {
    text-align: center;
    display: flex;
    position: relative;

    .logo {
      border-radius: 50px;
      position: absolute;
      width: 46px;
      left: 50%;
      top: 50%;
      padding: 7px;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }
}

.segment_toolbar {
  ion-segment {
    background: transparent;

    // background: var(--ion-color-white);
    // border: 1px solid rgba(0, 0, 0, 0.15) !important;
    // padding: 2px;
    // border-radius: 10px;
    ion-segment-button {
      // --background: #fff;
      // --background-checked: var(--ion-color-secondary);
      --color-checked: var(--ion-color-secondary);
      --indicator-color: transparent;
      // --border-radius: 10px;
      height: 36px;
      min-height: 40px;
      --color: var(--ion-color-white);
      --padding-bottom: 5px;
      --padding-end: 10px;
      --padding-start: 10px;
      --padding-top: 5px;
      --transition: background-color 15ms linear;
      font-size: 12px;
      min-width: fit-content;
      margin: 0;

      ion-icon {
        font-size: 16px;
        margin-top: 10px;
      }

      ion-label {
        margin-top: 2px;
        // margin-bottom: 5px;
        font-size: 12px;
      }
    }
  }
}

.normal_seg {
  // height: 50px;

  ion-segment-button {
    --background: linear-gradient(96deg, #341145 0%, #672289 100%) !important;
    --color: var(--ion-color-white) !important;

    --indicator-color: var(--ion-color-secondary) !important;
    --indicator-height: 5px;
    --color-checked: var(--ion-color-secondary) !important;
    --background-checked: linear-gradient(96deg,
        #341145 0%,
        #672289 100%) !important;

    ion-label {
      color: white !important;
      font-size: 14px;
    }
  }
}

ion-accordion {
  border: 1px solid #ddd;
  padding: 8px 12px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 10px;

  ion-item {
    min-height: 30px;

    ion-icon {
      margin-inline-end: 5px !important;
      margin-inline-start: 5px !important;
    }
  }

  ion-item[slot="header"] {
    border: 0 !important;
    background: transparent;

    ion-label {
      font-size: 14px !important;
      white-space: normal !important;
      font-weight: 600;
      color: var(--ion-color-primary);
    }

    button {
      background: transparent;
      border: 0 !important;
    }
  }

  ion-item[slot="content"] {
    ion-label {
      margin-top: 0px;
      margin-bottom: 5px;
      font-size: 13px !important;
      font-weight: normal;
      color: var(--ion-color-dark);
      text-align: justify;
      opacity: 0.7;
      white-space: normal !important;
    }
  }
}

html[dir="ltr"] {
  .content_section .app_feature ion-label {
    background: linear-gradient(270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7002510477) 100%) !important;
  }

  // .content_section .app_feature ion-icon {
  //   // transform: rotate(180deg);
  // }

  .pay_method .item_inner .selected {
    left: auto !important;
    right: 15px;
  }
}

ion-toast {
  //   --background: var(--ion-color-dark);
  --color: #fff;
  --button-color: #fff;
  --border-radius: 5px;
  font-size: 14px;

  .toast-button-group {
    .toast-button {
      font-size: 1.2em !important;
    }

    .toast-button-icon {
      font-size: 1.2em !important;
    }
  }
}

.toast-class-i {
  --background: var(--ion-color-primary) !important;
}

.toast-class-e {
  --background: var(--ion-color-danger) !important;
}

.toast-class-s {
  --background: var(--ion-color-success) !important;
}

.error {
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px 0px;
  border-radius: 20px;
}

.text-center {
  text-align: center;
}

.timer_div {
  text-align: center;

  .timer_message {
    color: var(--ion-color-primary);
  }

  .timer_count {
    color: var(--ion-color-primary);
    margin: 0px;
  }
}

.label_kls {
  margin-bottom: 0px;
}

// audio::-webkit-media-controls-panel {
//   background-color: #fff;
// }

.q_block .item_inner .q_details p * {
  margin: 0 !important;
}

// .choosen_answer {
//   // background: #341145;
//   border-radius: 10px;
//   padding: 2px 2px;
//   margin: 5px;
//   border: none;
//   outline-offset: 4px;
//   color: #341145 !important;
//   // font-size: 14px;
//   font-weight: 800;
//   position: relative;

//   // text-decoration: underline dotted #341145;
//   &::after {
//     content: "";
//     height: 1px;
//     width: 100%;
//     border-bottom: 2px dotted #341145;
//     position: absolute;
//     bottom: 0px;
//     left: 0;
//   }

// }

.ck-content {
  min-height: 100px;
}

.ck.ck-toolbar>.ck-toolbar__items {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
}

.openLinkOnTxt {
  color: #30adfc !important;
  text-decoration: underline;
  margin: 0px;
}

.question_num {
  align-items: center;
  margin-bottom: 15px;
  overflow-x: auto;
  overflow-y: unset;
  // height: 55px;
  white-space: nowrap;
  border: 2px solid rgba(103, 34, 137, 0.1);
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-flex;
  overflow-x: auto;
  width: 100%;

  .q_num {
    // background-image: url(assets/img/q-num.png);
    background: var(--ion-color-secondary);
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-size: cover;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 10px;
    border-radius: 5px;

    span {
      font-size: 20px;
      color: var(--ion-color-white);
      font-weight: 700;
      // margin-right: 10px;
      // transform: rotate(-5deg);
      font-family: revert !important;
    }
  }

  .solved {
    background: var(--ion-color-primary);
  }

  .plus {
    background: var(--ion-color-secondary);
    width: 40px;
    height: 40px;
    background-size: cover;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 10px;
    border-radius: 5px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ion-icon {
      font-size: 30px;
      line-height: 30px;
      padding: 0px;
      margin: 0px;
      width: 30px;
    }
  }
}

.progress_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

round-progress {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  left: 0;
  top: 0;
}

.stepper-content {
  position: relative;
  display: block;

  .stepper {
    display: block;
    margin: 10px 40px 0px;
    width: calc(100% - 80px);
    height: 80px;
    position: relative;

    .circles {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;
      z-index: 9999;

      span {
        display: flex;
        justify-content: center;
        position: relative;

        p {
          display: block;
          margin-top: 30px;
          position: absolute;
          font-size: 11px;
          line-height: 1;
          white-space: normal;
          text-align: center;
          min-width: 60px;
          width: 70px;
          padding: 0px 5px;
        }
      }

      span:before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #fff !important;
        border: 2px solid var(--ion-color-secondary);
        box-sizing: border-box;
      }

      span:after {
        content: "";
        display: none;
        background: transparent;
        border-width: 0px 0px 2px 2px;
        border-color: #ffffff;
        border-style: solid;
        width: 8px;
        height: 3px;
        position: absolute;
        right: -5px;
        top: 7px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
      }

      .active {
        p {
          font-weight: bold;
        }
      }

      .active:after {
        display: block;
      }

      .active:before {
        background: var(--ion-color-primary) !important;
        border: 2px solid var(--ion-color-secondary);
      }

      .first:after {
        right: 12px;
      }
    }

    .circles::before {
      content: "";
      height: 2px;
      z-index: 0;
      right: 0px;
      top: 10px;
      background: var(--ion-color-primary);
      position: absolute;
      right: 12px;
    }

    .circles:after {
      content: "";
      height: 2px;
      z-index: -1;
      right: 0px;
      top: 10px;
      width: calc(100% - 20px);
      background: var(--ion-color-secondary) !important;
      position: absolute;
      right: 12px;
    }
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 16px);
    position: relative;
    z-index: 9999;
    margin-inline-start: -8px;
    height: auto;
    margin-bottom: -15px;

    .icon {
      padding: 0px;

      img {
        width: 16px;
        height: auto;
        display: block;
        opacity: 1;
      }

      ion-icon {
        font-size: 16px;
        color: var(--ion-color-primary);
      }
    }
  }
}

app-attachments {
  z-index: 10;

  // width: 100%;
  audio {
    width: 100%;
    height: 46px;
  }
}

// audio::-webkit-media-controls-panel {
//   background: linear-gradient(-45deg, #fa3838, var(--ion-color-secondary));
//   height: 46px;
//   color: #fff !important;
// }

// audio::-webkit-media-controls-timeline {
//   background-color: rgb(251, 251, 251, 20%);
//   border-radius: 25px;
//   margin-left: 10px;
//   margin-right: 10px;
// }

// audio::-webkit-media-controls-current-time-display,
// audio::-webkit-media-controls-time-remaining-display {
//   color: #fff;
// }

// audio::-webkit-media-controls-volume-slider {
//   color: #fff;
//   background: #fff;
// }

// audio::-webkit-media-controls-mute-button {
//   color: #fff !important;
//   background-color: rgb(251, 251, 251, 20%);
//   border-radius: 50%;
// }

// audio::-webkit-media-controls-play-button {
//   background-color: rgb(251, 251, 251, 20%);
//   border-radius: 50%;
// }

video {
  width: 100%;
  // height: 200px;
}

.staging_version {
  ion-content {
    background-image: url("assets/images/test_version.jpeg");
    background-color: rgb(251 251 251 / 80%);
    background-blend-mode: lighten;
  }
}

.sub_question {
  margin-inline-start: 25px;
}

.q_head {
  app-attachments {
    .attachments {
      margin-top: 10px;
    }

    audio {
      width: 100%;
    }
  }
}

math {
  font-weight: bold;
}

.math_type {

  .table th,
  .table td {
    padding: 5px;
    text-align: center;
  }

  p {
    // display: flex;
    align-items: center;
    flex-wrap: wrap;

  }
  img {
    width: 50% !important ;
  }
}

.small_font {
  font-size: 9px;
}

.bolder {
  font-weight: 600;
}

// .alert-button :last-child {
//   font-weight: normal !important;
// }

// .alert-button:first-child {
//   span {
//     font-weight: bold !important;
//   }
// }

.ltr_content {
  direction: ltr !important;
}

.err_msg {
  z-index: 100;
  position: absolute;
  top: 0px;
  background: var(--ion-color-secondary);
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  text-align: center;
  transition: all ease-in-out 300ms;
  height: 0;
  line-height: 26px;
  // transform: scaleY(0);
}

ion-header {
  transition: all ease-in-out 300ms;
  // border-bottom-left-radius: 15px;
  // border-bottom-right-radius: 15px;
  // background: linear-gradient(96deg, rgba(52, 17, 69, 1) 0%, rgba(103, 34, 137, 1) 100%);
}

.error_connection_msg {
  .err_msg {
    height: 26px;
    top: var(--ion-safe-area-top, 0) !important;
  }

  ion-toolbar:first-of-type {
    padding-top: calc(var(--ion-safe-area-top, 0) + 26px) !important;
  }
}

.qBlock,
.q_block {
  span {
    p {
      font-size: 15px !important;
      white-space: normal;
      line-height: 1.5;
    }
  }

  .q_head {
    ion-text {
      height: fit-content !important;
    }

    // * {
    //   height: fit-content !important;
    // }
    padding-bottom: 10px !important;

    ion-label {
      p {
        font-size: 16px !important;
        font-weight: bold !important;

      }
    }

    strong {
      // width: 100% !important;
    }
  }
}

[dir="ltr"] .item-detail-icon {
  transform: rotate(180deg) !important;
}

// html[dir=ltr] {
// ion-item[detail=true]{
// background: #ccc;
// }
// }

app-upload-multi-files {
  width: 100%;
}

app-upload-single-file {
  width: 100%;
}

.flex_space {
  display: flex;
  justify-content: space-between;
}

.checkbox-checked {
  .item_inner {
    // background: rgb(52 17 69 / 15%);
    background: rgba(1, 144, 100, 0.1) !important;
  }
}

.calendar-day {
  font-size: 12px !important;
}

[dir="ltr"] .q_bank_actions {
  right: 10px;
  left: auto !important;
}

.load_more {
  margin: 0px;

  ion-button {
    margin: 4px;
    --background: #ddd;
    color: var(--ion-color-dark);
    --background-activated: #fff;
    --background-activated-opacity: 1;
    --background-focused: #fff;
    --background-focused-opacity: 1;
    --background-hover: #fff;
  }
}

.no_margin {
  margin: 0px;
}

.powerd_by {
  text-align: center;
  border-radius: 10px;
  padding: 10px;

  p {
    color: var(--ion-color-primary) !important;
    // font-family: arial !important;
    font-size: 14px;
    // font-weight: 600;
    margin: 0px;
  }

  img {
    height: 15px;
  }
}

hr {
  background: #d9d9d9;
}

page-display-img {
  .toolbar-background {
    background: #000 !important;
  }

  :host(.ion-color) .toolbar-background {
    background: transparent !important;
  }
}

app-home {
  .finished {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;

    .item_inner {
      opacity: 1 !important;
    }
  }
}

pinch-zoom {
  background-color: transparent !important;

  // min-height: 100%;
  .content_zoom {
    height: 100%;
    width: 100%;
  }
}

app-answer-explain {
  pinch-zoom {
    min-height: 100%;
  }
}

.audio_control {
  direction: rtl;
  audio {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.show_explain {
  height: 40px;
  min-height: 40px;

  width: 40px;
  margin-inline-start: 5px;
  z-index: 99;
  --padding-start: 0px;
  --padding-end: 0px;
  padding: 5px;
  border-radius: 5px;
  background: var(--ion-color-primary);

  ion-img {
    width: 100%;
  }
}

// qustion Component{
// .show_explain_div {
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   bottom: 0px;

//   .show_explain {
//     height: 40px;
//     width: 40px;
//     margin-inline-start: 5px;
//     z-index: 99;
//     --padding-start: 0px;
//     --padding-end: 0px;
//     padding: 5px;
//     border-radius: 5px;
//     background: var(--ion-color-primary);

//     ion-img {
//       width: 100%;
//     }

//     button {}
//   }
// }

ion-checkbox {
  --border-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6) !important;
}

.question_block {
  border: 1px solid #dddddd;
  border-radius: 10px;
  background: var(--ion-color-white);
  padding: 10px;
  margin-bottom: 10px;

  ion-item {
    --min-height: 40px;
    padding: 3px 5px;

    ion-checkbox {
      margin: 0px;
      margin-inline-end: 10px;
      // opacity: 1 !important;

      ion-label {
        overflow-x: auto !important;
        position: relative !important;
        z-index: 2 !important;
      }
    }

    ion-label {
      overflow-x: auto;
      font-size: 14px;
      opacity: 1 !important;
      white-space: normal !important;
    }
  }

  p {
    font-size: 14px;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
  }

  .head_question {
    padding: 0px;
    margin-bottom: 10px;
    padding-bottom: 10px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ion-text {
      margin: 0px;
      margin-inline-end: 5px;
      padding: 0px;
      border-radius: 5px;
      text-align: center;
      background: var(--ion-color-primary);
      color: var(--ion-color-white);
      width: auto;
      min-width: 30px;
      line-height: 30px;
      white-space: nowrap;
      padding: 0 5px !important;
      width: auto;
      overflow: hidden;
      flex-shrink: 0;
      height: fit-content;
    }

    ion-label {
      font-size: 14px;
      display: flex;
      white-space: normal;
      overflow-x: auto;
    }

    span {
      // flex-grow: 1;
      // padding: 0 5px;
    }

    .q_title {
      flex-grow: 1;
      font-size: 14px;
      overflow-x: auto;
    }

    .audio_control {
      width: 100%;
      padding-top: 35px;

      audio {
        width: 100%;
      }
    }

    video {
      margin-top: 10px;
      width: 100%;
    }
  }

  .q_head {
    padding: 1px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ion-text {
      margin: 0px;
      margin-inline-end: 5px;
      padding: 0px;
      border-radius: 5px;
      text-align: center;
      background: var(--ion-color-primary);
      color: var(--ion-color-white);
      width: auto;
      min-width: 30px;
      line-height: 30px;
      white-space: nowrap;
      padding: 0 5px !important;
      width: auto;
      overflow: hidden;
      flex-shrink: 0;
      height: fit-content;
    }

    ion-label {
      font-size: 14px;
      display: flex;
      white-space: normal;
      overflow-x: auto;
    }

    span {
      // flex-grow: 1;
      // padding: 0 5px;
    }

    .q_title {
      flex-grow: 1;
      font-size: 14px;
      overflow-x: auto;
    }

    .audio_control {
      width: 100%;
      padding-top: 35px;

      audio {
        width: 100%;
      }
    }

    video {
      margin-top: 10px;
      width: 100%;
    }
  }

  .degree_a {
    display: inline-flex;
    align-items: center;
    margin-right: auto;

    .correct_ans_icon {
      font-size: 30px;
      color: var(--ion-color-success);
      background-color: unset;
    }

    .false_ans_icon {
      font-size: 30px;
      color: var(--ion-color-danger);
      background-color: unset;
    }
  }

  .correct_ans_icon {
    color: #fff;
    background-color: var(--ion-color-success);
    font-size: 15px;
    border-radius: 2px;
  }

  .false_ans_icon {
    color: #fff;
    background-color: var(--ion-color-danger);
    font-size: 15px;
    border-radius: 2px;
  }

  .correct_article_ans {
    border-radius: 5px;
    background: rgb(0 144 100 / 37%) !important;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .correct_ans {
    border-radius: 5px;
    background: rgb(0 144 100 / 37%) !important;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .false_ans {
    border-radius: 5px;
    background: rgb(255 0 17 / 37%) !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .line_svg_div {
    z-index: 1 !important;
  }

  figure {
    width: 100% !important;
    margin: 0;
    height: fit-content !important;
    float: unset !important;
    display: flex;

    table {
      width: 100% !important;
    }
  }

  .audio_answer {
    z-index: 999;
    width: 100%;
    pointer-events: auto !important;
    // display: flex;
  }

  .video_option {
    align-items: baseline;
  }

  .video_answer {
    display: flex;
    width: 100%;
    align-self: flex-end;
    z-index: 999;
    pointer-events: auto !important;
  }

  .connect_question {
    ion-label {
      svg text {
        fill: #000 !important;
      }

      svg polyline,
      line {
        stroke: #000 !important;
      }
    }

    ion-grid {
      position: relative;

      .line_svg_div {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
    }

    ion-item {
      background: rgba(174, 174, 174, 0.251) !important;
      // --background: rgba(174, 174, 174, 0.251) !important;
      // background: var(--ion-color-secondary) !important;
      border: 5px solid var(--ion-color-secondary) !important;
      border-radius: 10px;
      padding: 5px 10px;
      margin-bottom: 15px;
      width: fit-content;
      max-width: 70%;
      z-index: 1;

      ion-label {
        white-space: normal;
        color: #000;
        display: grid;
        text-align: center;

        img {
          //width: 100px;
          height: 75px;
        }

        span {
          overflow-x: auto;
        }
      }

      ion-icon {
        color: #fff;
      }
    }

    .clicked {
      border: 5px solid var(--ion-color-primary) !important;
    }

    ion-col:first-child {
      ion-item {
        margin-inline-end: auto;
      }
    }

    ion-col:last-child {
      ion-item {
        margin-inline-start: auto;
      }

      // .clicked {
      //   pointer-events: none;
      // }
    }
  }

  .rearrange {
    .orderd_list {
      width: 100%;
      border: 2px solid rgba(103, 34, 137, 0.1);
      min-height: 50px;
      margin-top: 30px;
      margin-bottom: 30px;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      flex-flow: wrap;
      justify-content: center;
      border-radius: 10px;

      .orderd_item {
        position: relative;
        background: transparent;
        border-radius: 10px;
        padding: 10px;
        margin: 5px;
        // border: none;
        background: rgba(174, 174, 174, 0.251);
        border: 5px solid var(--ion-color-success);

        outline-offset: 4px;
        color: #000;
        font-size: 14px;
        z-index: 1;
        width: auto;
        min-width: 40px;
        text-align: center;

        p {
          margin: 0px;
        }

        // &::after {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;
        //   background: rgba(174, 174, 174, 0.251);
        //   will-change: transform;
        //   transform: translateY(2px);
        //   z-index: -2;
        //   transition:
        //     transform 600ms cubic-bezier(.3, .7, .4, 1);
        // }

        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;
        //   border: 5px solid var(--ion-color-primary);
        //   z-index: -1;
        //   box-sizing: border-box;
        // }

        img {
          height: 40px !important;
          width: auto !important;
        }
      }
    }

    .unorderd_list {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      justify-content: center;

      .unorderd_item {
        position: relative;
        background: transparent;
        border-radius: 10px;
        padding: 10px;
        margin: 5px;
        border: 5px solid var(--ion-color-primary);
        background: rgba(174, 174, 174, 0.251);
        outline-offset: 4px;
        color: #000;
        font-size: 14px;
        z-index: 1;
        width: auto;
        min-width: 40px;
        text-align: center;

        p {
          margin: 0px;
        }

        // &::after {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;
        //   background: rgba(174, 174, 174, 0.251);
        //   will-change: transform;
        //   transform: translateY(2px);
        //   border: 5px solid var(--ion-color-secondary);
        //   z-index: -2;
        //   transition:
        //     transform 600ms cubic-bezier(.3, .7, .4, 1);
        // }

        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;
        //   border: 5px solid var(--ion-color-secondary);
        //   z-index: -1;
        //   box-sizing: border-box;
        // }

        span {
          width: auto;
        }

        img {
          height: 40px !important;
          width: auto !important;
        }
      }

      .added {
        border: 5px solid var(--ion-color-secondary);
        pointer-events: none;

        // &::after {
        //   display: none;
        // }

        // &::before {
        //   display: none;
        // }

        span {
          opacity: 0;
          visibility: hidden;
        }
      }

      .right_orderd_item {
        // &::after {
        border: 5px solid var(--ion-color-primary);
        // }

        // &::before {
        //   border: 5px solid var(--ion-color-success);
        // }
      }
    }

    .hiden {
      display: none;
    }
  }

  .multi_complete {
    .q_list {
      .q_item {
        font-size: 16px;
      }
    }

    .unorderd_list {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      justify-content: center;

      svg text {
        fill: #000 !important;
      }

      svg polyline,
      line {
        stroke: #000 !important;
      }

      .unorderd_item {
        position: relative;
        background: transparent;
        border-radius: 10px;
        padding: 10px;
        margin: 5px;
        border: none;
        outline-offset: 4px;
        color: #000;
        font-size: 14px;
        background: rgba(174, 174, 174, 0.251);

        border: 5px solid var(--ion-color-secondary);
        z-index: 1;
        width: auto;
        min-width: 40px;
        text-align: center;

        // &::after {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;
        //   will-change: transform;
        //   transform: translateY(2px);
        //   z-index: -2;
        //   transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
        // }

        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;

        //   z-index: -1;
        //   box-sizing: border-box;
        // }

        p {
          margin: 0px;
        }
      }

      .added {
        border: 5px solid var(--ion-color-secondary);
        pointer-events: none;
        padding: 5px;

        // &::after {
        //   display: none;
        // }

        // &::before {

        //   display: none;
        // }

        span {
          opacity: 0;
          visibility: hidden;
        }
      }

      .selected {
        // border: 5px solid var(--ion-color-primary);

        // &::before {
        // content: "";
        border: 5px solid var(--ion-color-primary);
        // }
      }

      img {
        height: 40px !important;
        width: auto !important;
      }
    }

    .blank_hover {
      // padding: 0px 5px;
      color: var(--ion-color-secondary);

      span {
        p {
          span {
            color: var(--ion-color-secondary) !important;
          }
        }
      }

      svg text {
        fill: var(--ion-color-secondary) !important;
      }

      svg polyline,
      line {
        stroke: var(--ion-color-secondary) !important;
      }

      img {
        height: 40px !important;
        width: auto !important;
      }
    }

    .hide_q {
      display: none;
    }

    .show_rigth_value {
      .hide_q {
        display: inline;
      }
    }

    .right {
      color: var(--ion-color-success);

      svg text {
        fill: var(--ion-color-success) !important;
      }

      svg polyline,
      line {
        stroke: var(--ion-color-success) !important;
      }
    }

    .wrong {
      padding-inline-end: 5px;
      text-decoration: line-through;
      color: var(--ion-color-danger);

      svg text {
        fill: var(--ion-color-danger) !important;
      }

      svg polyline,
      line {
        stroke: var(--ion-color-danger) !important;
      }
    }

    .no_answer {
      padding-inline-end: 5px;
    }

    .added {
      // background: #ff6600;
      pointer-events: none;

      // &::after {
      //   display: none;
      // }

      &::before {
        display: none;
      }

      span {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .select_some_item {
    .blank_hover {
      background: #6e4b4b36;
      border-radius: 8px;
      padding: 0px 5px;
    }
  }

  .choice {
    ion-label {
      overflow-x: auto !important;
      position: relative !important;
      z-index: 2 !important;
      margin-right: 10px;
    }
  }
}

.download_btn {
  background: var(--ion-color-secondary);
  padding: 5px !important;
  color: #fff !important;

  font-size: 18px !important;
}

.no_border {
  border: unset;
}

// .ckEditor_ltr {
//   .ck-content {
//     direction: ltr !important;
//   }
// }

// .ckEditor_rtl {
//   .ck-content {
//     direction: rtl !important;
//   }
// }
//}

.q_article_list {
  .q_head {
    border: 0px !important;
    border-width: 0px !important;

    button {
      border: 0px !important;
      border-width: 0px !important;
    }

    .item-native {
      border: 0px !important;
      border-width: 0px !important;
    }

    .ion-accordion-toggle-icon {
      color: var(--ion-color-primary);
    }
  }
}

.target_animation {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

// .target_animation{
//   background: linear-gradient(0deg, rgb(84, 5, 95) 0%, rgb(21, 21, 132) 35%, rgb(184, 5, 151) 100%);
//   background-position:0;
//   background-size: 100% 300%;
// 	-webkit-animation: bg-slide 5s infinite both;
// 	        animation: bg-slide 5s infinite both;
// }

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

// @-webkit-keyframes bg-slide {
//   0% {
//     background-position: 50% 100%;
//   }
//   50% {
//     background-position: 50% 0%;
//   }
//   100% {
//     background-position: 50% 100%;
//   }
// }
// @keyframes bg-slide {
//   0% {
//     background-position: 50% 100%;
//   }
//   50% {
//     background-position: 50% 0%;
//   }
//   100% {
//     background-position: 50% 100%;
//   }
// }
.grops_files {
  border: 1px solid #ddd;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 5px;
  background: #fff;
}

.ion-accordion-toggle-icon {
  background: var(--ion-color-secondary);
  padding: 1px !important;
  color: #fff !important;
  border-radius: 5px;
  font-size: 15px !important;
  margin: 0px;
}

svg {
  direction: ltr;
  margin-bottom: -7px;
}

.note_div {
  font-size: 20px;
  color: #fff;
  box-sizing: border-box;
  --background: linear-gradient(93deg, #ff6600 0%, #672289 100%) !important;
  --padding-end: 10px;
  --padding-start: 10px;
  --border-radius: 5px;

  ion-label {
    font-weight: 600;
  }

  padding: 0px !important;
  margin-bottom: 10px;
}

// .android_class_parent {
//   .q_strong_android {
//     font-weight: 600;
//   }
// }

div[dir="rtl"] {
  .start_ {
    position: absolute;
    left: unset;
    right: 0px !important;
  }

  .end_ {
    position: absolute;
    left: 0px;
    right: unset !important;
  }
}

div[dir="ltr"] {
  .start_ {
    position: absolute;
    left: 0px;
    right: unset !important;
  }

  .end_ {
    position: absolute;
    right: 0px;
    left: unset !important;
  }
}

.copy_code {
  .form-control {
    height: 50px;
    margin-inline-end: 10px;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    background: #fbfbfb;
    white-space: nowrap;
    padding: 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #ced4da !important;

    ion-text {
      text-align: left;
      direction: ltr;
    }
  }

  ion-button {
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    --background: #fbfbfb !important;
    background: #fbfbfb !important;
    border: 1px solid #ced4da !important;
    margin: 0px !important;
    --padding-start: 10px;
    --padding-end: 10px;

    ion-icon {
      color: #495057;
      padding: 0;
      font-size: 1.4rem;
    }
  }
}

.customPopover {
  --offset-y: -100px;
  --height: fit-content;
}

.text_tags_dispay_none {
  text {
    display: none;
  }
}

.blank_hover {
  * {
    display: inline !important;
  }
}

.toast-container * {
  color: #fff;
}

.modal-backdrop.show {
  display: none;
}

.modal.show {
  background: rgba(0, 0, 0, 0.5);
  padding-top: 50px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-track:hover {
  background: #f7f7f7;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}

.inner-scroll {
  scrollbar-width: thin;
}

svg text {
  // font-size: 0.98em;
}

.widget-visible {
  iframe {
    padding: 50px !important;
  }
}

ion-col:has(> input.completeInputs) {
  padding: 2px 5px !important;
}

.completeInputs {
  height: 100%;
  background: transparent;
  background-color: transparent;
  background: none;
  font-size: 14px;
  overflow: hidden;
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px;
  background: #fff;
  border: 1px inset rgba(0, 0, 0, 0.15);
  margin-top: 0px;
  height: 34px;
  width: 100px;
  padding: 5px;
  color: var(--ion-color-dark);
  font-weight: 500;
}

.completeInputs:focus-visible {
  outline: none;
}

.normalWrap {
  white-space: normal !important;
}

.alert-input-error {
  border: 0.55px solid var(--ion-color-danger) !important;

  ::-webkit-input-placeholder {
    color: var(--ion-color-danger) !important;
  }

  ::-moz-placeholder {
    color: var(--ion-color-danger) !important;
  }

  :-ms-input-placeholder {
    color: var(--ion-color-danger) !important;
  }

  :-moz-placeholder {
    color: var(--ion-color-danger) !important;
  }
}

.border-test {
  border: 1px solid red !important;
}

.border-test1 {
  border: 1px solid blue !important;
}

.border-test2 {
  border: 1px solid green !important;
}

.q_strong_android p svg {
  direction: ltr !important;
  margin-bottom: -7px !important;
}

.LoginAlertCustom {

  .default-btn.loginAlertRegisterBtn,
  .default-btn.loginAlertCancelBtn {
    color: #fff;
    margin: 0 5px;
    border-radius: 3px;
  }

  .alert-button-group.sc-ion-alert-ios {
    flex-wrap: nowrap;
    width: 90%;
    margin: 8px auto;

    // btn container
    .default-btn.loginAlertRegisterBtn,
    .default-btn.loginAlertCancelBtn {
      min-width: unset;
      font-size: 14px;
    }
  }
}





.math_type>p[style*="text-align:right;"] {
  direction: rtl;
}

.math_type>p[dir=rtl] {
  text-indent: 0;
}

.math_type>p[style*="direction:rtl;"] {
  direction: rtl;
}

.math_type>p[style*="text-align:left;"] {
  direction: ltr;
}

.math_type>p[dir=ltr] {
  text-indent: inherit;
}

.math_type>p[style*="direction:ltr;"] {
  direction: ltr;
}


.single-course {
  background-color: transparent;
  margin-bottom: 15px;

  .thumbnail {
    padding: 0;
    z-index: 1;
    height: 200px;
    position: relative;

    a {

      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      overflow: hidden;
      display: block;
      height: 200px;

      &:after {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    img {
      border-radius: 0px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      transition: all ease-in-out 200ms;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .wishlist {
      z-index: 2;
      position: absolute;
      top: 10px;
      right: 10px;

      i {
        color: #fff;
        cursor: pointer;
        background: rgba(45, 40, 78, 0.2);
        backdrop-filter: blur(10px);
        width: 40px;
        height: 40px;
        line-height: 42px;
        text-align: center;
        border-radius: 100%;
        border: 0 none;
        padding: 0;
        display: inline-block;
        transition: all ease-in-out 200ms;

        &:hover {
          background: var(--ion-color-secondary);
        }
      }

      &.liked {
        i {
          background: var(--ion-color-secondary);
        }
      }
    }
    .deletelist {
      z-index: 2;
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 19px;
      background: transparent !important;
      color: black;
      margin-top: -3px;
     
    }
    .price {
      background: var(--gradient-secondary);
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 15px;
      margin-top: -33px;
    }
  }

  .content {
    padding: 25px;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    // background: rgba(103, 34, 137, 0.1);
    filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.1));

    .title {
      font-size: 1rem;
      color: var(--color);
      font-weight: 600;
      transition: all ease-in-out 200ms;

      a {
        color: var(--color);
        transition: all ease-in-out 200ms;

        &:hover {
          color: var(--ion-color-secondary);
        }
      }
    }

    .course-price {
      font-size: 16px;
      font-weight: 600;
      color: var(--ion-color-secondary);
      // margin-bottom: 10px;

      span {
        margin-inline-end: 5px;
      }

      .price-after {
        color: $hint-color;
        text-decoration: line-through;
      }
    }

    h6 {
      font-size: 13px;
      color: var(--color);

      i {
        font-size: 14px;
        margin-inline-end: 4px;
      }
    }

    .course-meta {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        font-size: 13px;
        color: var(--color);
        margin-inline-end: 20px;
        position: relative;

        i {
          color: var(--color);
          opacity: 0.8;
          font-size: 14px;
          margin-inline-end: 4px;
        }
      }
    }
  }

}

[dir="ltr"] {
  .single-course {
    .thumbnail {
      .wishlist {
        right: auto !important;
        left: 10px !important;
      }

      .price {
        right: 15px !important;
        left: auto !important;
      }
    }
  }

  app-courses {
    .main_tabs {
      .nav-tabs {
        .default-btn {
          left: auto !important;
          right: 0 !important;
        }
      }
    }
  }
}

.second-color-bg {
  background: #672289 !important;
}
.second-color-text {
  background: #672289 !important;
}
:host {
  --background: var(--ion-color-step-50, #f00) !important;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

// style sorce for questions

.question_block .q_head  {
  .source {
    font-size: 12px;
      color: gray;
      margin: 0;
      margin-right: auto;
  }
}
.light-btn {
  --ion-color-base: transparent !important;
  --background: #fff2ea; /* Updated background color */
  background: #fff2ea; /* Updated background color */
  --background-activated: #fff2ea;
  --background-focused: #fff2ea;
  --background-hover: #fff2ea;
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #FF6600; /* Updated text color */
  color: #FF6600; /* Updated text color */

  button {
    background: transparent !important;
  }
}



  .popup-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px;

    h4{
      font-size: 18px;
      font-weight: 700;
      color: #FF6600;
    }

    span{
        cursor: pointer;
        color: rgba(20, 27, 52, 1);
        i{
          font-size: 22px;
        }
    }
  }

.record {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  i{
      color: #FF6600;
      font-size: 18px;
  }
}

.popup_body {
  &::after {
      content: '';
      position:absolute ;
  }
}

.report_ques {
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 5px;
  i{
    font-size: 16px;
    color: #ff6600 !important;
  }
}