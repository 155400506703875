/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
  font-family: "DroidKufi-Regular";
  src: url("./assets/fonts/DroidKufi-Regular.ttf") format("truetype");
}
iframe {
  width: 100%;
}

.select-icon {
  width: 25px;
  height: 25px;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  opacity: unset !important;
  margin-inline: 35px;
}

.action-sheet-button.profile {
  font-weight: bold;
  margin-inline-end: 9px;
}

.action-sheet-icon.sc-ion-action-sheet-md {
  margin-inline-end: 10px;
}

.action-sheet-cancel {
  font-size: 20px;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
span,
a,
label {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

html,
td,
th,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
span,
button,
input,
a,
ion-toast,
label,
ion-segment-button,
ion-item,
ion-input,
ion-label,
ion-title,
ion-select,
ion-text,
ion-fab-button,
ion-badge,
ion-button {
  font-family: "DroidKufi-Regular", sans-serif !important;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

a {
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100% !important;
}

ion-content {
  --padding-bottom: 15px;
  --padding-top: 15px;
  --padding-end: 15px;
  --padding-start: 15px;
}

ion-title {
  font-size: 15px;
  text-align: center;
}

app-join-meet ion-content {
  --padding-bottom: 0px;
  --padding-top: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
}

textarea,
input {
  caret-color: var(--ion-color-primary);
}

.block_title {
  text-align: start;
}
.block_title h2 {
  color: var(--ion-color-primary);
  font-size: 16px;
  border-inline-start: 4px solid var(--ion-color-secondary);
  padding-inline-start: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: bold;
}
.block_title p {
  font-size: 15px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
}
.block_title span {
  color: var(--ion-color-primary);
}

ion-toolbar {
  --background: linear-gradient(96deg,
      rgba(52, 17, 69, 1) 0%,
      rgba(103, 34, 137, 1) 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  --border-width: 0px;
  --min-height: 50px;
}

.header-md::after {
  display: none;
}

.md ion-title {
  font-size: 16px;
  text-align: center;
  width: 60%;
  margin: auto;
  position: absolute;
  left: 20%;
  top: 0px;
  height: 100%;
}

ion-button {
  --border-radius: 5px;
  border-radius: 5px;
  overflow: hidden !important;
  --padding-bottom: 10px;
  --padding-top: 10px;
  min-height: 48px;
  letter-spacing: 0;
  font-size: 15px;
  --padding-bottom: 0;
  --padding-top: 0;
}

ion-searchbar {
  font-size: 14px;
  --icon-color: var(--ion-color-primary);
  padding-bottom: 10px !important;
}
ion-searchbar .searchbar-input-container {
  height: 40px !important;
  font-size: 14px;
}
ion-searchbar .searchbar-input-container input {
  font-size: 14px !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.cat_sec {
  border-radius: 10px;
  padding: 15px 15px;
  position: relative;
  transition: all ease-in-out 300ms;
  height: 100%;
  overflow: hidden;
}
.cat_sec:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../src/assets/wave-none.svg");
  opacity: 0.5;
  right: 0px;
  bottom: 0px;
  transition: all ease-in-out 300ms;
}
.cat_sec:hover:after {
  width: 100%;
}
.cat_sec .inner_div {
  position: relative;
  z-index: 1;
}
.cat_sec .inner_div:hover {
  text-decoration: none;
}
.cat_sec .inner_div img {
  height: 35px;
  width: 35px;
  object-fit: cover;
}
.cat_sec .inner_div h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--ion-color-white);
  font-size: 16px;
  text-align: start;
  font-size: 15px;
  line-height: 2;
}
.cat_sec .inner_div h4 b {
  color: var(--ion-color-white);
}

.content_section .item_1 .cat_sec {
  background: #cd2b36;
}

.content_section .item_2 .cat_sec {
  background: #019064;
}

.content_section .item_4 .cat_sec {
  background-color: #7605ae;
}

.content_section .item_3 .cat_sec {
  background-color: #ff6600;
}

.content_section .item_5 .cat_sec {
  background-color: #7279b1;
}

.content_section .item_6 .cat_sec {
  background-color: #bd0875;
}

.content_section .item_7 .cat_sec {
  background-color: #2c3ec9;
}

.content_section .item_8 .cat_sec {
  background-color: #1e9bb1;
}

.content_section .item_9 .cat_sec {
  background-color: #ca2fdf;
}

.content_section .item_10 .cat_sec {
  background-color: #27922d;
}

.content_section .item_11 .cat_sec {
  background-color: #b18a0a;
}

.content_section .item_12 .cat_sec {
  background-color: #e94e4e;
}

ion-buttons ion-back-button {
  font-size: 14px;
  --icon-padding-end: 10px;
  --icon-padding-start: 10px;
}
ion-buttons ion-button {
  height: auto;
  margin: auto;
  min-height: 32px;
}
ion-buttons ion-button ion-icon {
  font-size: 24px;
}

ion-toggle {
  height: 24px;
  width: 40px;
  --handle-height: 14px;
  --handle-spacing: 5px;
  --handle-width: 14px;
}

.default-btn {
  --ion-color-base: transparent !important;
  --background: transparent;
  background: linear-gradient(-45deg, #fa3838, var(--ion-color-secondary), #fa3838, var(--ion-color-secondary));
  --background-activated: linear-gradient(-45deg,
      #fa3838,
      var(--ion-color-secondary),
      #fa3838,
      var(--ion-color-secondary));
  --background-focused: linear-gradient(-45deg,
      #fa3838,
      var(--ion-color-secondary),
      #fa3838,
      var(--ion-color-secondary));
  --background-hover: linear-gradient(-45deg,
      #fa3838,
      var(--ion-color-secondary),
      #fa3838,
      var(--ion-color-secondary));
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #fff;
  color: #fff;
}
.default-btn button {
  background: transparent !important;
}

.green-btn {
  --ion-color-base: transparent !important;
  --background: transparent;
  background: linear-gradient(-45deg, #0AAF63, var(--ion-color-secondary), #0AAF63, var(--ion-color-secondary));
  --background-activated: linear-gradient(-45deg,
      #0AAF63,
      var(--ion-color-secondary),
      #0AAF63,
      var(--ion-color-secondary));
  --background-focused: linear-gradient(-45deg,
      #0AAF63,
      var(--ion-color-secondary),
      #0AAF63,
      var(--ion-color-secondary));
  --background-hover: linear-gradient(-45deg,
      #0AAF63,
      var(--ion-color-secondary),
      #0AAF63,
      var(--ion-color-secondary));
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #fff;
  color: #fff;
}
.green-btn button {
  background: transparent !important;
}

.second-btn {
  --ion-color-base: transparent !important;
  --background: transparent;
  background: var(--ion-color-primary);
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #fff;
  color: #fff;
}
.second-btn button {
  background: transparent !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.btn_small {
  height: 40px;
  min-height: 40px;
  --padding-start: 10px;
  --padding-end: 10px;
  font-size: 12px;
}

.btn_round {
  border-radius: 35px;
}

ion-tab-button ion-icon {
  font-size: 18px;
}
ion-tab-button ion-label {
  font-size: 13px;
}

ion-content {
  --background: transparent;
  background: #fff;
  background-image: url("assets/background.svg");
  background-repeat: no-repeat;
  background-size: 350px 100%;
}
ion-content .content_section {
  z-index: 2;
}
ion-content ion-list {
  --background: transparent;
  background: transparent !important;
}

app-login {
  background-image: url("assets/background.svg");
  background-repeat: no-repeat;
  background-size: 350px 100%;
}
app-login ion-header ion-buttons ion-icon {
  color: var(--ion-color-primary);
}
app-login ion-content {
  background: transparent;
  background-image: none;
}

ion-item {
  background: transparent !important;
  --background: transparent !important;
  --background-hover: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
ion-item ion-icon {
  margin-inline-end: 15px;
  font-size: 18px;
}
ion-item ion-label {
  font-size: 14px !important;
  font-weight: 500;
  margin: 0;
}

ion-list-header {
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  min-height: auto;
}
ion-list-header ion-label {
  margin: 0;
}

.input_item {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px !important;
  background: #fff !important;
  --background: #fff !important;
  border: 1px inset rgba(0, 0, 0, 0.15) !important;
  margin-top: 15px;
  height: 48px;
  --min-height: 48px;
}
.input_item ion-input {
  --background: transparent !important;
  background: none !important;
  --color: var(--ion-color-dark);
  --padding-bottom: 5px !important;
  --padding-end: 15px !important;
  --padding-start: 15px !important;
  --padding-top: 5px !important;
  --placeholder-color: var(--ion-color-dark);
  --placeholder-opacity: 0.35 !important;
  font-size: 14px !important;
  overflow: hidden !important;
  height: 100%;
}
.input_item input {
  height: 100%;
  background: transparent !important;
  background-color: transparent !important;
}
.input_item ion-icon {
  color: #341145;
  font-size: 20px;
  margin: 0px;
  padding-inline-end: 14px;
  padding-inline-start: 14px;
}
.input_item ion-label {
  margin: 0px;
  padding-inline-end: 15px;
  padding-inline-start: 15px;
}
.input_item .palceholder {
  opacity: 0.5;
}

.email_input {
  direction: ltr;
}

.phone_input {
  direction: ltr;
  padding-right: 14px;
}
.phone_input ion-select {
  min-width: 70px;
  width: fit-content;
  padding-inline-end: 10px;
  padding-inline-start: 10px;
  font-size: 14px;
}
.phone_input ion-input {
  padding-inline-start: 0px;
  --padding-start: 0px;
  direction: rtl;
}
.phone_input ion-input input {
  padding-inline-start: 0px !important;
}

.searchbar_input {
  margin-bottom: 15px;
  --min-height: 40px;
  height: 40px;
  border: 0px !important;
}
.searchbar_input ion-icon {
  padding-inline-end: 5px;
  padding-inline-start: 10px;
}
.searchbar_input ion-button {
  margin: 0px;
  --border-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background: transparent !important;
  --background: var(--ion-color-secondary);
  height: 40px;
  min-height: 40px;
  --padding-start: 8px;
  --padding-end: 8px;
  width: 50px;
}
.searchbar_input ion-button .button-native {
  background: transparent !important;
}
.searchbar_input ion-input {
  padding-inline-start: 5px;
  --padding-start: 5px !important;
}

[dir=ltr] .searchbar_input ion-button {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

ion-toolbar .searchbar_input {
  margin: 0px 10px 10px;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
}

::-moz-placeholder {
  font-size: 14px !important;
}

:-ms-input-placeholder {
  font-size: 14px !important;
}

:-moz-placeholder {
  font-size: 14px !important;
}

.textarea_item {
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
  background: #fff !important;
  --background: #fff !important;
}
.textarea_item ion-label {
  color: #fff !important;
  margin: 0px;
  font-size: 14px !important;
  margin-bottom: 5px;
}
.textarea_item ion-textarea {
  border-radius: 5px !important;
  --border-radius: 5px !important;
  --background: #fff !important;
  --color: var(--ion-color-dark) !important;
  --padding-bottom: 10px !important;
  --padding-end: 15px !important;
  --padding-start: 15px !important;
  --padding-top: 10px !important;
  --placeholder-color: var(--ion-color-dark) !important;
  --placeholder-opacity: 0.35 !important;
  min-height: 100px !important;
  font-size: 14px !important;
  overflow: hidden !important;
  margin-top: 0;
}
.textarea_item ion-textarea textarea {
  min-height: 100px !important;
}

.select_item {
  background: #fff !important;
  --background: #fff !important;
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
  height: 48px;
}
.select_item ion-label {
  color: #fff !important;
  margin: 0px;
  font-size: 14px !important;
  margin-bottom: 5px;
}
.select_item ion-select {
  border-radius: 5px !important;
  width: 100%;
  font-size: 14px !important;
  --padding-end: 15px !important;
  --padding-start: 15px !important;
}

.input_upload {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px !important;
  background: #fff !important;
  --background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  margin-top: 15px;
}
.input_upload ion-input {
  --background: transparent !important;
  background: none !important;
  --color: var(--ion-color-dark);
  --padding-bottom: 5px !important;
  --padding-end: 15px !important;
  --padding-start: 15px !important;
  --padding-top: 5px !important;
  --placeholder-color: var(--ion-color-dark);
  --placeholder-opacity: 0.35 !important;
  font-size: 14px !important;
  overflow: hidden !important;
  opacity: 0;
  width: 50%;
  height: 40px;
  position: absolute;
  top: 0px;
}
.input_upload ion-label {
  display: flex !important;
  align-items: center !important;
  max-width: 100% !important;
}
.input_upload ion-label ion-icon {
  margin-inline-end: 5px;
  color: #252525;
  opacity: 0.5;
  padding: 0;
  min-width: 20px;
}
.input_upload ion-label span {
  font-weight: normal;
  font-size: 14px;
  color: #252525;
  opacity: 0.5;
  white-space: normal;
  line-height: 1.5em;
  padding: 5px;
}
.input_upload input {
  background: transparent !important;
  background-color: transparent !important;
  font-size: 14px !important;
}
.input_upload ion-icon {
  color: #341145;
  font-size: 20px;
  margin: 0px;
  padding-inline-end: 14px;
  padding-inline-start: 14px;
}
.input_upload ion-label {
  margin: 0px;
  padding-inline-end: 15px;
  padding-inline-start: 15px;
}
.input_upload .palceholder {
  opacity: 0.5;
}

.countdown_section span {
  line-height: 1;
}
.countdown_section span .item {
  width: 50px !important;
  min-width: 50px !important;
  text-align: center !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff !important;
  margin: 0;
  padding: 2px;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
}

::-webkit-input-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
  font-family: "DroidKufi-Regular" !important;
}

::-moz-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
  font-family: "DroidKufi-Regular" !important;
}

:-ms-input-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
}

:-moz-placeholder {
  color: var(--ion-color-dark) !important;
  opacity: 0.5 !important;
  font-family: "DroidKufi-Regular" !important;
}

ion-grid {
  padding: 0;
}

.custom_actionSheet {
  --background: #ffffff;
}
.custom_actionSheet .action-sheet-title {
  font-size: 14px;
  color: var(--ion-color-dark);
  font-weight: bold;
}
.custom_actionSheet .action-sheet-group {
  border-radius: 10px;
}
.custom_actionSheet .action-sheet-group .action-sheet-button {
  font-size: 14px;
  color: var(--ion-color-dark);
  font-weight: normal;
  padding: 0px;
}
.custom_actionSheet .action-sheet-group .action-sheet-button .action-sheet-button-inner {
  justify-content: start !important;
  pointer-events: auto !important;
  width: 100%;
  height: 100%;
  padding: 0px 15px;
}
.custom_actionSheet .action-sheet-group .action-sheet-button ion-icon {
  margin: 0px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  color: #fff;
  border-radius: 30px;
  margin-inline-end: 10px !important;
}
.custom_actionSheet .action-sheet-group .action-sheet-button:nth-child(1) ion-icon {
  background-color: var(--ion-color-primary);
}
.custom_actionSheet .action-sheet-group .action-sheet-button:nth-child(3) ion-icon {
  background-color: var(--ion-color-success);
}
.custom_actionSheet .action-sheet-group .action-sheet-button:nth-child(2) ion-icon {
  background-color: var(--ion-color-secondary);
}
.custom_actionSheet .action-sheet-group .action-sheet-button:nth-child(4) ion-icon {
  background-color: var(--ion-color-danger);
}
.custom_actionSheet .action-sheet-group-cancel {
  border-radius: 10px;
  background-color: var(--ion-color-primary);
  border-radius: 10px;
}
.custom_actionSheet .action-sheet-group-cancel .action-sheet-cancel {
  height: 44px;
  padding: 10px 20px;
  background: linear-gradient(-45deg, #fa3838, var(--ion-color-secondary), #fa3838, var(--ion-color-secondary));
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
}
.custom_actionSheet .action-sheet-group-cancel .action-sheet-cancel .action-sheet-button-inner {
  justify-content: center !important;
  color: #ffffff;
}
.custom_actionSheet .action-sheet-group-cancel .action-sheet-cancel::after {
  display: none;
}

ion-popover {
  --max-height: 90vh !important;
  --min-width: 270px !important;
  --backdrop-opacity: 0.5 !important;
}
ion-popover .pop_item {
  --padding-start: 12px;
  --padding-end: 12px;
  color: var(--ion-color-primary);
}
ion-popover .pop_item ion-icon {
  color: var(--ion-color-secondary);
  margin-inline-end: 8px;
}
ion-popover .pop_item ion-img {
  width: 20px;
  margin-inline-end: 8px;
}

.auto-width-popover {
  --min-width: unset !important;
}
.auto-width-popover ion-item {
  --padding-start: 10px;
}

.home-popover {
  --min-width: 270px !important;
}
.home-popover ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}
.home-popover ion-item {
  border: 1px solid #ddd;
  display: inline-table;
}
.home-popover ion-item:last-child {
  border: unset;
}

.audio-speed-popover {
  --min-width: unset !important;
  --max-width: 100px !important;
  --max-height: 200px !important;
}
.audio-speed-popover p {
  padding: 7px;
  margin: 0px;
}

ion-select-popover ion-item {
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}

ion-select-popover ion-list ion-item {
  --border-width: 0px !important;
  --border-color: #ffffff;
  border-bottom: 1px solid #ddd !important;
}
ion-select-popover ion-list ion-item:last-child {
  border-bottom: 0px !important;
}

.swiper-wrapper {
  margin-bottom: 20px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  margin-bottom: 0px !important;
  bottom: 0px;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 20px;
  height: 4px;
  border-radius: 0px;
  background: #fa3838;
}

ion-row {
  margin-inline-start: -5px;
  margin-inline-end: -5px;
}

ion-footer ion-col {
  padding: 0px 8px;
}
ion-footer ion-col[size="6"]:nth-child(odd) {
  padding-inline-end: 2px;
}
ion-footer ion-col[size="6"]:nth-child(odd) + ion-col {
  padding-inline-start: 2px;
}

.brife {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brife .inner {
  display: block;
  text-align: center;
}
.brife .inner ion-icon {
  font-size: 70px;
  color: var(--ion-color-primary);
  margin-bottom: 20px;
}
.brife .inner .notsolved_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.brife .inner .notsolved_img img {
  min-width: 150px;
  max-width: 150px;
  margin-bottom: 0px;
}
.brife .inner .notsolved_img span {
  font-size: 65px;
  color: var(--ion-color-white);
  font-weight: 700;
  margin-right: 20px;
  transform: rotate(-5deg);
  font-family: revert !important;
  position: absolute;
}
.brife .inner h4 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-primary);
}

.loading_spin,
.no_network,
.notification_msg,
.no_content,
.error_msg,
.message_content,
.success_msg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
}
.loading_spin .inner,
.no_network .inner,
.notification_msg .inner,
.no_content .inner,
.error_msg .inner,
.message_content .inner,
.success_msg .inner {
  display: block;
  text-align: center;
}
.loading_spin .inner ion-icon,
.no_network .inner ion-icon,
.notification_msg .inner ion-icon,
.no_content .inner ion-icon,
.error_msg .inner ion-icon,
.message_content .inner ion-icon,
.success_msg .inner ion-icon {
  font-size: 70px;
  color: var(--ion-color-primary);
  margin-bottom: 10px;
}
.loading_spin .inner img,
.no_network .inner img,
.notification_msg .inner img,
.no_content .inner img,
.error_msg .inner img,
.message_content .inner img,
.success_msg .inner img {
  min-width: 200px;
  max-width: 200px;
  margin-bottom: 0px;
}
.loading_spin .inner h4,
.no_network .inner h4,
.notification_msg .inner h4,
.no_content .inner h4,
.error_msg .inner h4,
.message_content .inner h4,
.success_msg .inner h4 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-primary);
}

.still_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
}
.still_loading .inner {
  text-align: center;
  display: flex;
  position: relative;
}
.still_loading .inner .logo {
  border-radius: 50px;
  position: absolute;
  width: 46px;
  left: 50%;
  top: 50%;
  padding: 7px;
  transform: translate(-50%, -50%);
  margin: 0;
}

.segment_toolbar ion-segment {
  background: transparent;
}
.segment_toolbar ion-segment ion-segment-button {
  --color-checked: var(--ion-color-secondary);
  --indicator-color: transparent;
  height: 36px;
  min-height: 40px;
  --color: var(--ion-color-white);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  --transition: background-color 15ms linear;
  font-size: 12px;
  min-width: fit-content;
  margin: 0;
}
.segment_toolbar ion-segment ion-segment-button ion-icon {
  font-size: 16px;
  margin-top: 10px;
}
.segment_toolbar ion-segment ion-segment-button ion-label {
  margin-top: 2px;
  font-size: 12px;
}

.normal_seg ion-segment-button {
  --background: linear-gradient(96deg, #341145 0%, #672289 100%) !important;
  --color: var(--ion-color-white) !important;
  --indicator-color: var(--ion-color-secondary) !important;
  --indicator-height: 5px;
  --color-checked: var(--ion-color-secondary) !important;
  --background-checked: linear-gradient(96deg,
      #341145 0%,
      #672289 100%) !important;
}
.normal_seg ion-segment-button ion-label {
  color: white !important;
  font-size: 14px;
}

ion-accordion {
  border: 1px solid #ddd;
  padding: 8px 12px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
}
ion-accordion ion-item {
  min-height: 30px;
}
ion-accordion ion-item ion-icon {
  margin-inline-end: 5px !important;
  margin-inline-start: 5px !important;
}
ion-accordion ion-item[slot=header] {
  border: 0 !important;
  background: transparent;
}
ion-accordion ion-item[slot=header] ion-label {
  font-size: 14px !important;
  white-space: normal !important;
  font-weight: 600;
  color: var(--ion-color-primary);
}
ion-accordion ion-item[slot=header] button {
  background: transparent;
  border: 0 !important;
}
ion-accordion ion-item[slot=content] ion-label {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 13px !important;
  font-weight: normal;
  color: var(--ion-color-dark);
  text-align: justify;
  opacity: 0.7;
  white-space: normal !important;
}

html[dir=ltr] .content_section .app_feature ion-label {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7002510477) 100%) !important;
}
html[dir=ltr] .pay_method .item_inner .selected {
  left: auto !important;
  right: 15px;
}

ion-toast {
  --color: #fff;
  --button-color: #fff;
  --border-radius: 5px;
  font-size: 14px;
}
ion-toast .toast-button-group .toast-button {
  font-size: 1.2em !important;
}
ion-toast .toast-button-group .toast-button-icon {
  font-size: 1.2em !important;
}

.toast-class-i {
  --background: var(--ion-color-primary) !important;
}

.toast-class-e {
  --background: var(--ion-color-danger) !important;
}

.toast-class-s {
  --background: var(--ion-color-success) !important;
}

.error {
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px 0px;
  border-radius: 20px;
}

.text-center {
  text-align: center;
}

.timer_div {
  text-align: center;
}
.timer_div .timer_message {
  color: var(--ion-color-primary);
}
.timer_div .timer_count {
  color: var(--ion-color-primary);
  margin: 0px;
}

.label_kls {
  margin-bottom: 0px;
}

.q_block .item_inner .q_details p * {
  margin: 0 !important;
}

.ck-content {
  min-height: 100px;
}

.ck.ck-toolbar > .ck-toolbar__items {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
}

.openLinkOnTxt {
  color: #30adfc !important;
  text-decoration: underline;
  margin: 0px;
}

.question_num {
  align-items: center;
  margin-bottom: 15px;
  overflow-x: auto;
  overflow-y: unset;
  white-space: nowrap;
  border: 2px solid rgba(103, 34, 137, 0.1);
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-flex;
  overflow-x: auto;
  width: 100%;
}
.question_num .q_num {
  background: var(--ion-color-secondary);
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-size: cover;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 10px;
  border-radius: 5px;
}
.question_num .q_num span {
  font-size: 20px;
  color: var(--ion-color-white);
  font-weight: 700;
  font-family: revert !important;
}
.question_num .solved {
  background: var(--ion-color-primary);
}
.question_num .plus {
  background: var(--ion-color-secondary);
  width: 40px;
  height: 40px;
  background-size: cover;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 10px;
  border-radius: 5px;
}
.question_num .plus span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.question_num .plus ion-icon {
  font-size: 30px;
  line-height: 30px;
  padding: 0px;
  margin: 0px;
  width: 30px;
}

.progress_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

round-progress {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  left: 0;
  top: 0;
}

.stepper-content {
  position: relative;
  display: block;
}
.stepper-content .stepper {
  display: block;
  margin: 10px 40px 0px;
  width: calc(100% - 80px);
  height: 80px;
  position: relative;
}
.stepper-content .stepper .circles {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 9999;
}
.stepper-content .stepper .circles span {
  display: flex;
  justify-content: center;
  position: relative;
}
.stepper-content .stepper .circles span p {
  display: block;
  margin-top: 30px;
  position: absolute;
  font-size: 11px;
  line-height: 1;
  white-space: normal;
  text-align: center;
  min-width: 60px;
  width: 70px;
  padding: 0px 5px;
}
.stepper-content .stepper .circles span:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff !important;
  border: 2px solid var(--ion-color-secondary);
  box-sizing: border-box;
}
.stepper-content .stepper .circles span:after {
  content: "";
  display: none;
  background: transparent;
  border-width: 0px 0px 2px 2px;
  border-color: #ffffff;
  border-style: solid;
  width: 8px;
  height: 3px;
  position: absolute;
  right: -5px;
  top: 7px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.stepper-content .stepper .circles .active p {
  font-weight: bold;
}
.stepper-content .stepper .circles .active:after {
  display: block;
}
.stepper-content .stepper .circles .active:before {
  background: var(--ion-color-primary) !important;
  border: 2px solid var(--ion-color-secondary);
}
.stepper-content .stepper .circles .first:after {
  right: 12px;
}
.stepper-content .stepper .circles::before {
  content: "";
  height: 2px;
  z-index: 0;
  right: 0px;
  top: 10px;
  background: var(--ion-color-primary);
  position: absolute;
  right: 12px;
}
.stepper-content .stepper .circles:after {
  content: "";
  height: 2px;
  z-index: -1;
  right: 0px;
  top: 10px;
  width: calc(100% - 20px);
  background: var(--ion-color-secondary) !important;
  position: absolute;
  right: 12px;
}
.stepper-content .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 16px);
  position: relative;
  z-index: 9999;
  margin-inline-start: -8px;
  height: auto;
  margin-bottom: -15px;
}
.stepper-content .icons .icon {
  padding: 0px;
}
.stepper-content .icons .icon img {
  width: 16px;
  height: auto;
  display: block;
  opacity: 1;
}
.stepper-content .icons .icon ion-icon {
  font-size: 16px;
  color: var(--ion-color-primary);
}

app-attachments {
  z-index: 10;
}
app-attachments audio {
  width: 100%;
  height: 46px;
}

video {
  width: 100%;
}

.staging_version ion-content {
  background-image: url("assets/images/test_version.jpeg");
  background-color: rgba(251, 251, 251, 0.8);
  background-blend-mode: lighten;
}

.sub_question {
  margin-inline-start: 25px;
}

.q_head app-attachments .attachments {
  margin-top: 10px;
}
.q_head app-attachments audio {
  width: 100%;
}

math {
  font-weight: bold;
}

.math_type .table th,
.math_type .table td {
  padding: 5px;
  text-align: center;
}
.math_type p {
  align-items: center;
  flex-wrap: wrap;
}
.math_type img {
  width: 50% !important;
}

.small_font {
  font-size: 9px;
}

.bolder {
  font-weight: 600;
}

.ltr_content {
  direction: ltr !important;
}

.err_msg {
  z-index: 100;
  position: absolute;
  top: 0px;
  background: var(--ion-color-secondary);
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  text-align: center;
  transition: all ease-in-out 300ms;
  height: 0;
  line-height: 26px;
}

ion-header {
  transition: all ease-in-out 300ms;
}

.error_connection_msg .err_msg {
  height: 26px;
  top: var(--ion-safe-area-top, 0) !important;
}
.error_connection_msg ion-toolbar:first-of-type {
  padding-top: calc(var(--ion-safe-area-top, 0) + 26px) !important;
}

.qBlock span p,
.q_block span p {
  font-size: 15px !important;
  white-space: normal;
  line-height: 1.5;
}
.qBlock .q_head,
.q_block .q_head {
  padding-bottom: 10px !important;
}
.qBlock .q_head ion-text,
.q_block .q_head ion-text {
  height: fit-content !important;
}
.qBlock .q_head ion-label p,
.q_block .q_head ion-label p {
  font-size: 16px !important;
  font-weight: bold !important;
}
[dir=ltr] .item-detail-icon {
  transform: rotate(180deg) !important;
}

app-upload-multi-files {
  width: 100%;
}

app-upload-single-file {
  width: 100%;
}

.flex_space {
  display: flex;
  justify-content: space-between;
}

.checkbox-checked .item_inner {
  background: rgba(1, 144, 100, 0.1) !important;
}

.calendar-day {
  font-size: 12px !important;
}

[dir=ltr] .q_bank_actions {
  right: 10px;
  left: auto !important;
}

.load_more {
  margin: 0px;
}
.load_more ion-button {
  margin: 4px;
  --background: #ddd;
  color: var(--ion-color-dark);
  --background-activated: #fff;
  --background-activated-opacity: 1;
  --background-focused: #fff;
  --background-focused-opacity: 1;
  --background-hover: #fff;
}

.no_margin {
  margin: 0px;
}

.powerd_by {
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}
.powerd_by p {
  color: var(--ion-color-primary) !important;
  font-size: 14px;
  margin: 0px;
}
.powerd_by img {
  height: 15px;
}

hr {
  background: #d9d9d9;
}

page-display-img .toolbar-background {
  background: #000 !important;
}
page-display-img :host(.ion-color) .toolbar-background {
  background: transparent !important;
}

app-home .finished {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}
app-home .finished .item_inner {
  opacity: 1 !important;
}

pinch-zoom {
  background-color: transparent !important;
}
pinch-zoom .content_zoom {
  height: 100%;
  width: 100%;
}

app-answer-explain pinch-zoom {
  min-height: 100%;
}

.audio_control {
  direction: rtl;
}
.audio_control audio {
  width: 100% !important;
  max-width: 100% !important;
}

.show_explain {
  height: 40px;
  min-height: 40px;
  width: 40px;
  margin-inline-start: 5px;
  z-index: 99;
  --padding-start: 0px;
  --padding-end: 0px;
  padding: 5px;
  border-radius: 5px;
  background: var(--ion-color-primary);
}
.show_explain ion-img {
  width: 100%;
}

ion-checkbox {
  --border-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6) !important;
}

.question_block {
  border: 1px solid #dddddd;
  border-radius: 10px;
  background: var(--ion-color-white);
  padding: 10px;
  margin-bottom: 10px;
}
.question_block ion-item {
  --min-height: 40px;
  padding: 3px 5px;
}
.question_block ion-item ion-checkbox {
  margin: 0px;
  margin-inline-end: 10px;
}
.question_block ion-item ion-checkbox ion-label {
  overflow-x: auto !important;
  position: relative !important;
  z-index: 2 !important;
}
.question_block ion-item ion-label {
  overflow-x: auto;
  font-size: 14px;
  opacity: 1 !important;
  white-space: normal !important;
}
.question_block p {
  font-size: 14px;
  height: auto;
  overflow: hidden;
  overflow-x: auto;
}
.question_block .head_question {
  padding: 0px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.question_block .head_question ion-text {
  margin: 0px;
  margin-inline-end: 5px;
  padding: 0px;
  border-radius: 5px;
  text-align: center;
  background: var(--ion-color-primary);
  color: var(--ion-color-white);
  width: auto;
  min-width: 30px;
  line-height: 30px;
  white-space: nowrap;
  padding: 0 5px !important;
  width: auto;
  overflow: hidden;
  flex-shrink: 0;
  height: fit-content;
}
.question_block .head_question ion-label {
  font-size: 14px;
  display: flex;
  white-space: normal;
  overflow-x: auto;
}
.question_block .head_question .q_title {
  flex-grow: 1;
  font-size: 14px;
  overflow-x: auto;
}
.question_block .head_question .audio_control {
  width: 100%;
  padding-top: 35px;
}
.question_block .head_question .audio_control audio {
  width: 100%;
}
.question_block .head_question video {
  margin-top: 10px;
  width: 100%;
}
.question_block .q_head {
  padding: 1px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.question_block .q_head ion-text {
  margin: 0px;
  margin-inline-end: 5px;
  padding: 0px;
  border-radius: 5px;
  text-align: center;
  background: var(--ion-color-primary);
  color: var(--ion-color-white);
  width: auto;
  min-width: 30px;
  line-height: 30px;
  white-space: nowrap;
  padding: 0 5px !important;
  width: auto;
  overflow: hidden;
  flex-shrink: 0;
  height: fit-content;
}
.question_block .q_head ion-label {
  font-size: 14px;
  display: flex;
  white-space: normal;
  overflow-x: auto;
}
.question_block .q_head .q_title {
  flex-grow: 1;
  font-size: 14px;
  overflow-x: auto;
}
.question_block .q_head .audio_control {
  width: 100%;
  padding-top: 35px;
}
.question_block .q_head .audio_control audio {
  width: 100%;
}
.question_block .q_head video {
  margin-top: 10px;
  width: 100%;
}
.question_block .degree_a {
  display: inline-flex;
  align-items: center;
  margin-right: auto;
}
.question_block .degree_a .correct_ans_icon {
  font-size: 30px;
  color: var(--ion-color-success);
  background-color: unset;
}
.question_block .degree_a .false_ans_icon {
  font-size: 30px;
  color: var(--ion-color-danger);
  background-color: unset;
}
.question_block .correct_ans_icon {
  color: #fff;
  background-color: var(--ion-color-success);
  font-size: 15px;
  border-radius: 2px;
}
.question_block .false_ans_icon {
  color: #fff;
  background-color: var(--ion-color-danger);
  font-size: 15px;
  border-radius: 2px;
}
.question_block .correct_article_ans {
  border-radius: 5px;
  background: rgba(0, 144, 100, 0.37) !important;
  margin-bottom: 8px;
  margin-top: 8px;
}
.question_block .correct_ans {
  border-radius: 5px;
  background: rgba(0, 144, 100, 0.37) !important;
  margin-bottom: 8px;
  margin-top: 8px;
}
.question_block .false_ans {
  border-radius: 5px;
  background: rgba(255, 0, 17, 0.37) !important;
  margin-bottom: 5px;
  margin-top: 5px;
}
.question_block .line_svg_div {
  z-index: 1 !important;
}
.question_block figure {
  width: 100% !important;
  margin: 0;
  height: fit-content !important;
  float: unset !important;
  display: flex;
}
.question_block figure table {
  width: 100% !important;
}
.question_block .audio_answer {
  z-index: 999;
  width: 100%;
  pointer-events: auto !important;
}
.question_block .video_option {
  align-items: baseline;
}
.question_block .video_answer {
  display: flex;
  width: 100%;
  align-self: flex-end;
  z-index: 999;
  pointer-events: auto !important;
}
.question_block .connect_question ion-label svg text {
  fill: #000 !important;
}
.question_block .connect_question ion-label svg polyline,
.question_block .connect_question ion-label line {
  stroke: #000 !important;
}
.question_block .connect_question ion-grid {
  position: relative;
}
.question_block .connect_question ion-grid .line_svg_div {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.question_block .connect_question ion-item {
  background: rgba(174, 174, 174, 0.251) !important;
  border: 5px solid var(--ion-color-secondary) !important;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 15px;
  width: fit-content;
  max-width: 70%;
  z-index: 1;
}
.question_block .connect_question ion-item ion-label {
  white-space: normal;
  color: #000;
  display: grid;
  text-align: center;
}
.question_block .connect_question ion-item ion-label img {
  height: 75px;
}
.question_block .connect_question ion-item ion-label span {
  overflow-x: auto;
}
.question_block .connect_question ion-item ion-icon {
  color: #fff;
}
.question_block .connect_question .clicked {
  border: 5px solid var(--ion-color-primary) !important;
}
.question_block .connect_question ion-col:first-child ion-item {
  margin-inline-end: auto;
}
.question_block .connect_question ion-col:last-child ion-item {
  margin-inline-start: auto;
}
.question_block .rearrange .orderd_list {
  width: 100%;
  border: 2px solid rgba(103, 34, 137, 0.1);
  min-height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: center;
  border-radius: 10px;
}
.question_block .rearrange .orderd_list .orderd_item {
  position: relative;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  background: rgba(174, 174, 174, 0.251);
  border: 5px solid var(--ion-color-success);
  outline-offset: 4px;
  color: #000;
  font-size: 14px;
  z-index: 1;
  width: auto;
  min-width: 40px;
  text-align: center;
}
.question_block .rearrange .orderd_list .orderd_item p {
  margin: 0px;
}
.question_block .rearrange .orderd_list .orderd_item img {
  height: 40px !important;
  width: auto !important;
}
.question_block .rearrange .unorderd_list {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: center;
}
.question_block .rearrange .unorderd_list .unorderd_item {
  position: relative;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  border: 5px solid var(--ion-color-primary);
  background: rgba(174, 174, 174, 0.251);
  outline-offset: 4px;
  color: #000;
  font-size: 14px;
  z-index: 1;
  width: auto;
  min-width: 40px;
  text-align: center;
}
.question_block .rearrange .unorderd_list .unorderd_item p {
  margin: 0px;
}
.question_block .rearrange .unorderd_list .unorderd_item span {
  width: auto;
}
.question_block .rearrange .unorderd_list .unorderd_item img {
  height: 40px !important;
  width: auto !important;
}
.question_block .rearrange .unorderd_list .added {
  border: 5px solid var(--ion-color-secondary);
  pointer-events: none;
}
.question_block .rearrange .unorderd_list .added span {
  opacity: 0;
  visibility: hidden;
}
.question_block .rearrange .unorderd_list .right_orderd_item {
  border: 5px solid var(--ion-color-primary);
}
.question_block .rearrange .hiden {
  display: none;
}
.question_block .multi_complete .q_list .q_item {
  font-size: 16px;
}
.question_block .multi_complete .unorderd_list {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: center;
}
.question_block .multi_complete .unorderd_list svg text {
  fill: #000 !important;
}
.question_block .multi_complete .unorderd_list svg polyline,
.question_block .multi_complete .unorderd_list line {
  stroke: #000 !important;
}
.question_block .multi_complete .unorderd_list .unorderd_item {
  position: relative;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  border: none;
  outline-offset: 4px;
  color: #000;
  font-size: 14px;
  background: rgba(174, 174, 174, 0.251);
  border: 5px solid var(--ion-color-secondary);
  z-index: 1;
  width: auto;
  min-width: 40px;
  text-align: center;
}
.question_block .multi_complete .unorderd_list .unorderd_item p {
  margin: 0px;
}
.question_block .multi_complete .unorderd_list .added {
  border: 5px solid var(--ion-color-secondary);
  pointer-events: none;
  padding: 5px;
}
.question_block .multi_complete .unorderd_list .added span {
  opacity: 0;
  visibility: hidden;
}
.question_block .multi_complete .unorderd_list .selected {
  border: 5px solid var(--ion-color-primary);
}
.question_block .multi_complete .unorderd_list img {
  height: 40px !important;
  width: auto !important;
}
.question_block .multi_complete .blank_hover {
  color: var(--ion-color-secondary);
}
.question_block .multi_complete .blank_hover span p span {
  color: var(--ion-color-secondary) !important;
}
.question_block .multi_complete .blank_hover svg text {
  fill: var(--ion-color-secondary) !important;
}
.question_block .multi_complete .blank_hover svg polyline,
.question_block .multi_complete .blank_hover line {
  stroke: var(--ion-color-secondary) !important;
}
.question_block .multi_complete .blank_hover img {
  height: 40px !important;
  width: auto !important;
}
.question_block .multi_complete .hide_q {
  display: none;
}
.question_block .multi_complete .show_rigth_value .hide_q {
  display: inline;
}
.question_block .multi_complete .right {
  color: var(--ion-color-success);
}
.question_block .multi_complete .right svg text {
  fill: var(--ion-color-success) !important;
}
.question_block .multi_complete .right svg polyline,
.question_block .multi_complete .right line {
  stroke: var(--ion-color-success) !important;
}
.question_block .multi_complete .wrong {
  padding-inline-end: 5px;
  text-decoration: line-through;
  color: var(--ion-color-danger);
}
.question_block .multi_complete .wrong svg text {
  fill: var(--ion-color-danger) !important;
}
.question_block .multi_complete .wrong svg polyline,
.question_block .multi_complete .wrong line {
  stroke: var(--ion-color-danger) !important;
}
.question_block .multi_complete .no_answer {
  padding-inline-end: 5px;
}
.question_block .multi_complete .added {
  pointer-events: none;
}
.question_block .multi_complete .added::before {
  display: none;
}
.question_block .multi_complete .added span {
  opacity: 0;
  visibility: hidden;
}
.question_block .select_some_item .blank_hover {
  background: rgba(110, 75, 75, 0.2117647059);
  border-radius: 8px;
  padding: 0px 5px;
}
.question_block .choice ion-label {
  overflow-x: auto !important;
  position: relative !important;
  z-index: 2 !important;
  margin-right: 10px;
}

.download_btn {
  background: var(--ion-color-secondary);
  padding: 5px !important;
  color: #fff !important;
  font-size: 18px !important;
}

.no_border {
  border: unset;
}

.q_article_list .q_head {
  border: 0px !important;
  border-width: 0px !important;
}
.q_article_list .q_head button {
  border: 0px !important;
  border-width: 0px !important;
}
.q_article_list .q_head .item-native {
  border: 0px !important;
  border-width: 0px !important;
}
.q_article_list .q_head .ion-accordion-toggle-icon {
  color: var(--ion-color-primary);
}

.target_animation {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.grops_files {
  border: 1px solid #ddd;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 5px;
  background: #fff;
}

.ion-accordion-toggle-icon {
  background: var(--ion-color-secondary);
  padding: 1px !important;
  color: #fff !important;
  border-radius: 5px;
  font-size: 15px !important;
  margin: 0px;
}

svg {
  direction: ltr;
  margin-bottom: -7px;
}

.note_div {
  font-size: 20px;
  color: #fff;
  box-sizing: border-box;
  --background: linear-gradient(93deg, #ff6600 0%, #672289 100%) !important;
  --padding-end: 10px;
  --padding-start: 10px;
  --border-radius: 5px;
  padding: 0px !important;
  margin-bottom: 10px;
}
.note_div ion-label {
  font-weight: 600;
}

div[dir=rtl] .start_ {
  position: absolute;
  left: unset;
  right: 0px !important;
}
div[dir=rtl] .end_ {
  position: absolute;
  left: 0px;
  right: unset !important;
}

div[dir=ltr] .start_ {
  position: absolute;
  left: 0px;
  right: unset !important;
}
div[dir=ltr] .end_ {
  position: absolute;
  right: 0px;
  left: unset !important;
}

.copy_code .form-control {
  height: 50px;
  margin-inline-end: 10px;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  background: #fbfbfb;
  white-space: nowrap;
  padding: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ced4da !important;
}
.copy_code .form-control ion-text {
  text-align: left;
  direction: ltr;
}
.copy_code ion-button {
  width: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  --background: #fbfbfb !important;
  background: #fbfbfb !important;
  border: 1px solid #ced4da !important;
  margin: 0px !important;
  --padding-start: 10px;
  --padding-end: 10px;
}
.copy_code ion-button ion-icon {
  color: #495057;
  padding: 0;
  font-size: 1.4rem;
}

.customPopover {
  --offset-y: -100px;
  --height: fit-content;
}

.text_tags_dispay_none text {
  display: none;
}

.blank_hover * {
  display: inline !important;
}

.toast-container * {
  color: #fff;
}

.modal-backdrop.show {
  display: none;
}

.modal.show {
  background: rgba(0, 0, 0, 0.5);
  padding-top: 50px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-track:hover {
  background: #f7f7f7;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}

.inner-scroll {
  scrollbar-width: thin;
}

.widget-visible iframe {
  padding: 50px !important;
}

ion-col:has(> input.completeInputs) {
  padding: 2px 5px !important;
}

.completeInputs {
  height: 100%;
  background: transparent;
  background-color: transparent;
  background: none;
  font-size: 14px;
  overflow: hidden;
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px;
  background: #fff;
  border: 1px inset rgba(0, 0, 0, 0.15);
  margin-top: 0px;
  height: 34px;
  width: 100px;
  padding: 5px;
  color: var(--ion-color-dark);
  font-weight: 500;
}

.completeInputs:focus-visible {
  outline: none;
}

.normalWrap {
  white-space: normal !important;
}

.alert-input-error {
  border: 0.55px solid var(--ion-color-danger) !important;
}
.alert-input-error ::-webkit-input-placeholder {
  color: var(--ion-color-danger) !important;
}
.alert-input-error ::-moz-placeholder {
  color: var(--ion-color-danger) !important;
}
.alert-input-error :-ms-input-placeholder {
  color: var(--ion-color-danger) !important;
}
.alert-input-error :-moz-placeholder {
  color: var(--ion-color-danger) !important;
}

.border-test {
  border: 1px solid red !important;
}

.border-test1 {
  border: 1px solid blue !important;
}

.border-test2 {
  border: 1px solid green !important;
}

.q_strong_android p svg {
  direction: ltr !important;
  margin-bottom: -7px !important;
}

.LoginAlertCustom .default-btn.loginAlertRegisterBtn,
.LoginAlertCustom .default-btn.loginAlertCancelBtn {
  color: #fff;
  margin: 0 5px;
  border-radius: 3px;
}
.LoginAlertCustom .alert-button-group.sc-ion-alert-ios {
  flex-wrap: nowrap;
  width: 90%;
  margin: 8px auto;
}
.LoginAlertCustom .alert-button-group.sc-ion-alert-ios .default-btn.loginAlertRegisterBtn,
.LoginAlertCustom .alert-button-group.sc-ion-alert-ios .default-btn.loginAlertCancelBtn {
  min-width: unset;
  font-size: 14px;
}

.math_type > p[style*="text-align:right;"] {
  direction: rtl;
}

.math_type > p[dir=rtl] {
  text-indent: 0;
}

.math_type > p[style*="direction:rtl;"] {
  direction: rtl;
}

.math_type > p[style*="text-align:left;"] {
  direction: ltr;
}

.math_type > p[dir=ltr] {
  text-indent: inherit;
}

.math_type > p[style*="direction:ltr;"] {
  direction: ltr;
}

.single-course {
  background-color: transparent;
  margin-bottom: 15px;
}
.single-course .thumbnail {
  padding: 0;
  z-index: 1;
  height: 200px;
  position: relative;
}
.single-course .thumbnail a {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
  display: block;
  height: 200px;
}
.single-course .thumbnail a:after {
  background-color: rgba(0, 0, 0, 0);
}
.single-course .thumbnail img {
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: all ease-in-out 200ms;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.single-course .thumbnail .wishlist {
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 10px;
}
.single-course .thumbnail .wishlist i {
  color: #fff;
  cursor: pointer;
  background: rgba(45, 40, 78, 0.2);
  backdrop-filter: blur(10px);
  width: 40px;
  height: 40px;
  line-height: 42px;
  text-align: center;
  border-radius: 100%;
  border: 0 none;
  padding: 0;
  display: inline-block;
  transition: all ease-in-out 200ms;
}
.single-course .thumbnail .wishlist i:hover {
  background: var(--ion-color-secondary);
}
.single-course .thumbnail .wishlist.liked i {
  background: var(--ion-color-secondary);
}
.single-course .thumbnail .deletelist {
  z-index: 2;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 19px;
  background: transparent !important;
  color: black;
  margin-top: -3px;
}
.single-course .thumbnail .price {
  background: var(--gradient-secondary);
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  margin-top: -33px;
}
.single-course .content {
  padding: 25px;
  border-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.1));
}
.single-course .content .title {
  font-size: 1rem;
  color: var(--color);
  font-weight: 600;
  transition: all ease-in-out 200ms;
}
.single-course .content .title a {
  color: var(--color);
  transition: all ease-in-out 200ms;
}
.single-course .content .title a:hover {
  color: var(--ion-color-secondary);
}
.single-course .content .course-price {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-secondary);
}
.single-course .content .course-price span {
  margin-inline-end: 5px;
}
.single-course .content .course-price .price-after {
  color: #8989a2;
  text-decoration: line-through;
}
.single-course .content h6 {
  font-size: 13px;
  color: var(--color);
}
.single-course .content h6 i {
  font-size: 14px;
  margin-inline-end: 4px;
}
.single-course .content .course-meta {
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-course .content .course-meta li {
  display: inline-block;
  font-size: 13px;
  color: var(--color);
  margin-inline-end: 20px;
  position: relative;
}
.single-course .content .course-meta li i {
  color: var(--color);
  opacity: 0.8;
  font-size: 14px;
  margin-inline-end: 4px;
}

[dir=ltr] .single-course .thumbnail .wishlist {
  right: auto !important;
  left: 10px !important;
}
[dir=ltr] .single-course .thumbnail .price {
  right: 15px !important;
  left: auto !important;
}
[dir=ltr] app-courses .main_tabs .nav-tabs .default-btn {
  left: auto !important;
  right: 0 !important;
}

.second-color-bg {
  background: #672289 !important;
}

.second-color-text {
  background: #672289 !important;
}

:host {
  --background: var(--ion-color-step-50, #f00) !important;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.question_block .q_head .source {
  font-size: 12px;
  color: gray;
  margin: 0;
  margin-right: auto;
}

.light-btn {
  --ion-color-base: transparent !important;
  --background: #fff2ea; /* Updated background color */
  background: #fff2ea; /* Updated background color */
  --background-activated: #fff2ea;
  --background-focused: #fff2ea;
  --background-hover: #fff2ea;
  background-size: 800% 400%;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  --color: #FF6600; /* Updated text color */
  color: #FF6600; /* Updated text color */
}
.light-btn button {
  background: transparent !important;
}

.popup-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
}
.popup-title h4 {
  font-size: 18px;
  font-weight: 700;
  color: #FF6600;
}
.popup-title span {
  cursor: pointer;
  color: rgb(20, 27, 52);
}
.popup-title span i {
  font-size: 22px;
}

.record {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}
.record i {
  color: #FF6600;
  font-size: 18px;
}

.popup_body::after {
  content: "";
  position: absolute;
}

.report_ques {
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 5px;
}
.report_ques i {
  font-size: 16px;
  color: #ff6600 !important;
}